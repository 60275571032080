import { useState, useContext, useEffect } from "react";
import Button from "../../../components/Button";
import Numberfield from "../../../components/Numberfield";
import Datepicker from "../../../components/Datepicker";
import { Container, Header, Row, Content, Divider } from './styles';
import Modal from '@mui/material/Modal';
import Spinner from "../../../components/Spinner";
import Autocomplete from "../../../components/Autocomplete";
import AppContext from "../../../state/App.context";
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";
import { Close } from "../../../assets/icons";
import Table from '../../../components/Table';
import { UseEmployees } from "../../../hooks/useEmployees";
import moment from "moment";
import ModalRemoveStockWithdrawal from "../ModalRemoveStockWithdrawal";

const columns = [
  {
    name: 'Item',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'allocated_amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Retirado em',
    key: 'allocated_at',
    type: 'date',
    unit: '',
  },
  {
    name: 'Receptor',
    key: 'recipient_name',
    type: 'string',
    unit: '',
  },
]

export default function ModalStockWithdrawal({ open, width, height, selectedItem, setSelectedItem }) {
  const { employees } = UseEmployees();
  const { getFinishedProductOrderStockItems, getFinishedProductOrderStockWithdrawals, createFinishedProductOrderStockWithdrawal } = UseFinishedProductOrders();

  const [item, setItem] = useState({ id: 0, label: '' });
  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [allocatedAt, setAllocatedAt] = useState(moment().format('YYYY-MM-DD'));
  const [recipient, setRecipient] = useState({ id: 0, label: '' });

  const [stockWithdrawalsList, setStockWithdrawalsList] = useState([]);
  const [stockItemsList, setStockItemsList] = useState([]);

  const [selectedStockWithdrawalItem, setSelectedStockWithdrawalItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [showModalRemoveStockWithdrawal, setShowModalRemoveStockWithdrawal] = useState(false);

  const [refreshDataFromServer, setRefreshDataFromServer] = useState(0);

  useEffect(() => {
    async function getStockWithdrawals() {
      setStockWithdrawalsList([]);
      let result = await getFinishedProductOrderStockWithdrawals(selectedItem.item?.id_finished_product_order);

      if (result.success) {
        let stockWithdrawalsListToShow = result.data.data.map((stockWithdrawal) => {
          return {
            id: stockWithdrawal.id,
            product: stockWithdrawal.product,
            product_name: stockWithdrawal.product_name,
            allocated_amount: stockWithdrawal.allocated_amount,
            allocated_at: stockWithdrawal.allocated_at,
            recipient: stockWithdrawal.recipient,
            recipient_name: stockWithdrawal.recipient_name,
          }
        });

        setStockWithdrawalsList(stockWithdrawalsListToShow);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível obter os dados de retirada de estoque para o pedido de produto acabado: ' + result.message,
        });
      }
    }

    async function getStockItems() {
      setStockItemsList([]);
      let result = await getFinishedProductOrderStockItems();

      if (result.success) {
        let stockItemsListToShow = result.data.data.map((stockItem) => {
          return {
            id: stockItem.id,
            label: stockItem.product_name,
            stock: stockItem.remaining_amount,
          }
        });

        stockItemsListToShow.sort((a, b) => {
          const labelA = a.label.trim().toUpperCase();
          const labelB = b.label.trim().toUpperCase();

          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }

          return 0;
        });

        setStockItemsList(stockItemsListToShow);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível obter os itens de estoque para o pedido de produto acabado: ' + result.message,
        });
      }
    }

    getStockWithdrawals();
    getStockItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataFromServer]);

  function cleanAllInputs() {
    setItem({ id: 0, label: '' });
    setAllocatedAmount(0);
    setAllocatedAt('');
    setRecipient({ id: 0, label: '' });
  }

  function cleanAll() {
    cleanAllInputs();
    setSelectedStockWithdrawalItem(null);
  }

  let employeesOptions = [];

  employees && employees.forEach((employee) => {
    if (employee.isactive)
      employeesOptions.push({
        id: employee?.id_employee,
        label: employee?.full_name,
      });
  })

  employeesOptions.sort((a, b) => {
    const labelA = a.label.trim().toUpperCase();
    const labelB = b.label.trim().toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  async function saveOrEditStockWithdrawalAndUpdateList(event) {
    try {
      setLoading(true);
      setDisableSaveButton(true);
      event.preventDefault();

      if (item.label && (allocatedAmount > 0) && (allocatedAmount <= item.stock) &&
        recipient.label && allocatedAt && moment(allocatedAt).isValid()) {
        let stockWithdrawal = {
          id: null,
          product: item.id,
          product_name: item.label,
          allocated_amount: allocatedAmount,
          finished_product_order: selectedItem.item.id_finished_product_order,
          recipient: recipient.id,
          recipient_name: recipient.label,
          allocated_at: allocatedAt,
        }

        let result = await createFinishedProductOrderStockWithdrawal({
          item: stockWithdrawal.product,
          allocated_amount: stockWithdrawal.allocated_amount,
          finished_product_order: stockWithdrawal.finished_product_order,
          recipient: stockWithdrawal.recipient,
          allocated_at: stockWithdrawal.allocated_at,
        });

        if (result.success) {
          setRefreshDataFromServer((prev) => prev+1);
          setSelectedStockWithdrawalItem(null);
          cleanAllInputs();

          setSnack({
            open: true,
            severity: 'success',
            message: 'Retirada de estoque para pedido de produto acabado realizada com sucesso!',
          });
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Não foi possível realizar a retirada de estoque para pedido de produto acabado: ' + result.message,
          });
        }
      } else {
        if (item.label && item.stock) setAllocatedAmount(item.stock);

        setSnack({
          open: true,
          severity: 'error',
          message: 'Campos obrigatórios não preenchidos ou quantidade maior que a disponível em estoque.',
        });
      }
    } catch (err) {
      console.log(err);
      setSnack({
        open: true,
        severity: 'error',
        message: 'err',
      });
    } finally {
      setLoading(false);
      setDisableSaveButton(false);
    }
  }

  function handleSelectedCostItem(item) {
    setSelectedStockWithdrawalItem(item);

    if (item.mode === 'remove') {
      setShowModalRemoveStockWithdrawal(true);
      cleanAllInputs();
    }
  }

  function handleClose() {
    cleanAll();
    setSelectedItem({ open: false, mode: 'view', item: null });
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row><h2>Id do pedido de produto acabado: {selectedItem.item?.id_finished_product_order}</h2></Row>
          <Divider />
          <Row>
            <div style={{ width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'left', verticalAlign: 'text-top' }}>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={item}
                  width={230}
                  disabled={false}
                  options={stockItemsList}
                  fontSize={14}
                  label={'Item*'}
                  onChange={(value) => {
                    setAllocatedAmount(value.stock);
                    setItem(value);
                  }}
                ></Autocomplete>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Numberfield
                  label="Quantidade*"
                  width={230}
                  disabled={false}
                  value={allocatedAmount}
                  fontSize={14}
                  onChange={setAllocatedAmount}
                ></Numberfield>
              </div>
              <div style={{ width: 253, marginTop: -8, marginBottom: -8 }}>
                <Datepicker
                  type="date"
                  label="Retirado em"
                  width={230}
                  disabled={false}
                  value={allocatedAt}
                  fontSize={14}
                  onChange={setAllocatedAt}
                />
              </div>
              <div style={{ width: 253 }}>
                <Autocomplete
                  value={recipient}
                  width={230}
                  disabled={false}
                  options={employeesOptions}
                  fontSize={14}
                  label={'Receptor*'}
                  onChange={setRecipient}
                ></Autocomplete>
              </div>
            </div>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: "right", marginTop: '16px', marginBottom: '16px', paddingRight: '15px' }}>
            <Button
              label="Salvar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={disableSaveButton}
              onClick={saveOrEditStockWithdrawalAndUpdateList}
            ></Button>
            <Button
              label="Limpar"
              background="transparent"
              color="#256CE1"
              borderColor="#256CE1"
              disabled={false}
              onClick={cleanAll}
            ></Button>
          </Row>
          <Table
            columns={columns}
            rows={stockWithdrawalsList || []}
            hasRemoving={true}
            setSelectedItem={handleSelectedCostItem}
            height="auto"
          ></Table>
        </Content >
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Retirada de Item do Estoque para Pedido de Produto Acabado</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
        {showModalRemoveStockWithdrawal && (
          <ModalRemoveStockWithdrawal
            open={true}
            width={600}
            height={220}
            selectedItem={selectedStockWithdrawalItem}
            setSelectedItem={setSelectedStockWithdrawalItem}
            setShowModalRemoveStockWithdrawal={setShowModalRemoveStockWithdrawal}
            setRefreshDataFromServer={setRefreshDataFromServer}
          />
        )}
      </Container>
    </Modal>
  )
}