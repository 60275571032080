import React, { useState } from "react";
import {
  TableContainer,
  TableHeader,
  TableRow,
  Icons,
  NoData,
} from "./styles";
import { Remove, Edit, ArrowUp, ArrowDown } from "../../assets/icons/index";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatNumber, formatMoney } from "../../services/functions";
import { NoDataImg } from "../../assets/images";
import Table from "../Table";

export default function TableNestedItemsAndParcels({
  columns,
  rows,
  hasEditing,
  hasRemoving,
  setSelectedItem,
  height,
  actionsInFirstLine,
  fitWidth,
  onRowClick,
  columnsParcels,
  columnsItems,
}) {
  const [expandedParcelsRows, setExpandedParcelsRows] = useState({});
  const [expandedItemsRows, setExpandedItemsRows] = useState({});

  function toggleParcelRowExpansion(rowIndex) {
    setExpandedParcelsRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  }

  function toggleItemRowExpansion(rowIndex) {
    setExpandedItemsRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  }

  function createDynamicStyle() {
    try {
      let elementToAdd = fitWidth ? "150px " : "1fr ";
      let styleString = "";
      columns.map((item) => (
        styleString = styleString + elementToAdd
      ));
      if (hasEditing || hasRemoving) {
        styleString = styleString + elementToAdd;
      }
      return styleString;
    } catch (err) {
      console.log(err);
    }
  }
  let columnsWidths = createDynamicStyle();

  function RenderActions({ rowIndex, row }) {
    if (hasEditing || hasRemoving) {
      return (
        <Icons key={rowIndex + "i"}>
          <Tooltip title="Editar" key="edit">
            <img
              src={Edit}
              alt="Editar"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "edit",
                  ...rows[rowIndex],
                });
              }}
              style={{ display: hasEditing ? "flex" : "none" }}
            />
          </Tooltip>
          <Tooltip title="Remover" key="remove">
            <img
              src={Remove}
              alt="Remover"
              onClick={() => {
                setSelectedItem({
                  open: true,
                  mode: "remove",
                  ...rows[rowIndex],
                });
              }}
            />
          </Tooltip>
          {columnsItems && (
            <Tooltip title="Visualizar Itens" key="view-items">
              <img
                src={expandedItemsRows[rowIndex] ? ArrowUp : ArrowDown}
                alt="Itens"
                onClick={() => toggleItemRowExpansion(rowIndex)}
              />
            </Tooltip>
          )}
          {columnsParcels && (
            <Tooltip title="Visualizar Pagamento" key="view-payment">
              <img
                src={expandedParcelsRows[rowIndex] ? ArrowUp : ArrowDown}
                alt="Pagamento"
                onClick={() => toggleParcelRowExpansion(rowIndex)}
              />
            </Tooltip>
          )}
        </Icons>
      );
    } else {
      return <></>;
    }
  }

  function renderActionsRow(rowIndex, row) {
    return <RenderActions rowIndex={rowIndex} row={row}></RenderActions>;
  }

  function RenderRow({ rowIndex }) {
    const row = rows[rowIndex];
    return (
      <>
        {/* Linha principal */}
        <TableRow style={{ gridTemplateColumns: columnsWidths }} key={rowIndex}>
          {actionsInFirstLine && renderActionsRow(rowIndex, row)}
          {columns.map((column, index) => {
            const key = column.key;
            let value = row[key];
            const unit = column.unit;
            if (column.type === "date") {
              if (value) {
                value = moment(value).add(3, "hours").format("DD/MM/YYYY");
              }
            }
            if (column.type === "datetime") {
              if (value) {
                value = moment(value)
                  .add(3, "hours")
                  .format("DD/MM/YYYY [às] HH:mm");
              }
            }
            if (column.type === "boolean") {
              value = value ? "Sim" : "Não";
            }
            if (column.type === "money") {
              value = value ? formatMoney(value) : "R$ 0,00";
            }
            if (column.type === "float") {
              value = formatNumber(value);
            }
            if (column.type === "percent") {
              value = formatNumber(value * 100);
            }
            let valueToShow = value || value === 0 ? value + unit : "-";
            return (
              <Tooltip
                title={value + unit || "-"}
                key={index + "-" + rowIndex}
              >
                <h3
                  key={index + "-" + rowIndex}
                  onClick={() => {
                    setSelectedItem({
                      open: true,
                      mode: "visualize",
                      item: { ...row },
                    });
                    if (onRowClick) {
                      onRowClick(row);
                    }
                  }}
                >
                  {valueToShow}
                </h3>
              </Tooltip>
            );
          })}
          {!actionsInFirstLine && renderActionsRow(rowIndex, row)}
        </TableRow>

        {columnsItems && expandedItemsRows[rowIndex] && (
          <Table
            columns={columnsItems}
            rows={row.items || []}
            height="auto"
          ></Table>
        )}
        {columnsParcels && expandedParcelsRows[rowIndex] && (
          <Table
            columns={columnsParcels}
            rows={row.parcels || []}
            height="auto"
          ></Table>
        )}
      </>
    );
  }

  return (
    <TableContainer
      style={{
        width: fitWidth ? "fit-content" : "100%",
        height: height || "500px",
      }}
    >
      <TableHeader style={{ gridTemplateColumns: columnsWidths }}>
        {actionsInFirstLine && <h3>Ações</h3>}
        {columns.map((column) => (
          <h3 key={column.key}>{column.name}</h3>
        ))}
        {!actionsInFirstLine && <h3>Ações</h3>}
      </TableHeader>
      {rows.length > 0 ? (
        rows.map((row, index) => (
          <RenderRow key={index} rowIndex={index}></RenderRow>
        ))
      ) : (
        <NoData>
          <img src={NoDataImg} alt="No data" />
          <h4>Sem registros</h4>
        </NoData>
      )}
    </TableContainer>
  );
}
