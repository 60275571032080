import Button from "../../../components/Button";
import Autocomplete from "../../../components/Autocomplete";
import Datepicker from "../../../components/Datepicker";
import { UseCostCenters } from "../../../hooks/useCostCenters";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseTrips } from "../../../hooks/useTrips";
import { UseBoats } from "../../../hooks/useBoats";

export const harvestOptions = [
  {
    id: 2023,
    label: '2023',
  },
  {
    id: 2024,
    label: '2024',
  },
  {
    id: 2025,
    label: '2025',
  },
];

export default function OrdersFilters({ filters, setFilters, defaultFilters }) {
  const { costCenters } = UseCostCenters();
  const { subsidiaries } = UseSubsidiaries();
  const { trips } = UseTrips();
  const { boats } = UseBoats();

  let costCentersOptions = [];

  costCenters && !costCenters.error && costCenters.forEach((element) => {
    if (element.isactive) {
      costCentersOptions.push({
        id: element?.costs_center_name,
        label: element?.costs_center_name,
      })
    }
  });

  let subsidiariesOptions = [];

  subsidiaries && !subsidiaries.error && subsidiaries.forEach((element) => {
    if (element.isactive) {
      subsidiariesOptions.push({
        id: element?.subsidiary_name,
        label: element?.subsidiary_name,
      })
    }
  })

  let tripsOptions = [];

  trips && !trips.error && trips.forEach((trip) => {
    const index = tripsOptions.findIndex((option) => option.label === trip.name);
    if (index === -1 && trip.isactive && tripsOptions.findIndex((element) => element.id === trip.trips_name) === -1) {
      tripsOptions.push({
        id: trip?.trips_name,
        label: trip?.trips_name
      })
    }
  })

  let boatsOptions = [];

  boatsOptions && !boatsOptions.error && boats.forEach((element) => {
    if (element.isactive) {
      boatsOptions.push(
        {
          id: element?.boats_name,
          label: element?.boats_name,
        }
      )
    }
  })

  function cleanFilters() {
    setFilters({ ...defaultFilters });
  }

  return (
    <div style={{ marginTop: 16 }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          label="Limpar filtros"
          background="transparent"
          color="#54577A"
          borderColor="#54577A"
          disabled={false}
          onClick={() => { cleanFilters() }}
        ></Button>
      </div>
      <Datepicker
        label="Criado entre"
        width={200}
        disabled={false}
        value={filters?.startDate}
        fontSize={'0.7rem'}
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.startDate = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }} />
      <Datepicker
        label="e"
        width={200}
        disabled={false}
        value={filters?.endDate}
        fontSize={'0.7rem'}
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.endDate = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }} />
      <br></br>
      <Autocomplete
        value={filters?.trip}
        width={200}
        disabled={false}
        options={[{ id: "''", label: 'Todas' }, ...tripsOptions]}
        fontSize={'0.7rem'}
        label="Viagem"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.trip = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.harvest}
        width={200}
        disabled={false}
        options={[{ id: "''", label: 'Todas' }, ...harvestOptions]}
        fontSize={'0.7rem'}
        label="Safra"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.harvest = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.boat}
        width={200}
        disabled={false}
        options={[{ id: "''", label: 'Todos' }, ...boatsOptions]}
        fontSize={'0.7rem'}
        label="Barco"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.boat = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.subsidiary}
        width={200}
        disabled={false}
        options={[{ id: "''", label: 'Todas' }, ...subsidiariesOptions]}
        fontSize={'0.7rem'}
        label="Subsidiária"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.subsidiary = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
      <br></br>
      <Autocomplete
        value={filters?.costCenter}
        width={200}
        disabled={false}
        options={[{ id: "''", label: 'Todos' }, ...costCentersOptions]}
        fontSize={'0.7rem'}
        label="Centro de Custo"
        onChange={(element) => {
          let filtersCopy = { ...filters };
          filtersCopy.costCenter = element;
          filtersCopy.pages = 1;
          setFilters(filtersCopy);
        }}
      ></Autocomplete>
    </div>
  )
}