
import { createContext, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();
interface FinishedProductOrder {
    id: string;
    input_order_id: string;
    notes: string;
}

interface FinishedProductOrdersRequest {
    startDate: string;
    endDate: string;
    trip?: string | string[];
    boat?: string | string[];
    harvest: string;
    subsidiary?: string | string[];
    costCenter?: string | string[];
    pages: number;
    limit: number;
}

interface CostParcel {
    id: string;
    finished_product_item_cost: string;
    number: number;
    value: number;
    expiration_date: string;
    payment_date: string;
    status: string;
}
interface FinishedProductOrderCost {
    id: string;
    product: string;
    amount: number;
    unit_price: number;
    cost_category: string;
    supplier: string;
    finished_product_order: string;
    payment_type: string;
    discount: number;
    notes: string;
    parcels: CostParcel[];
}

interface BeneficiationItemParcel {
    id: string;
    finished_product_item_sale: string;
    number: number;
    value: number;
    expiration_date: string;
    payment_date: string;
    status: string;
}

interface BeneficiationItem {
    id: string;
    finished_product_beneficiation_item: string;
    unit_price: number;
    raw_material_transaction: string;
    raw_material_amount: number;
    finished_product: string;
    produced_amount: number;
    weakfish: number;
}
interface FinishedProductOrderBeneficiationItem {
    id: string;
    finished_product_order: string;
    beneficiation_company: string;
    batch: string;
    items: BeneficiationItem[];
    parcels: BeneficiationItemParcel[];
}

interface SaleParcel {
    id: string;
    finished_product_item_sale: string;
    number: number;
    value: number;
    expiration_date: string;
    payment_date: string;
    status: string;
}
interface FinishedProductSale {
    id: string;
    dt_sale: string;
    provider: string;
    seller: string;
    finished_product_order_item: string;
    amount: number;
    unit_price: number;
    coast_category: string;
    payment_type: string;
    discount: number;
    notes: string;
    parcels: SaleParcel[];
}

interface FinishedProductOrderPurchase {
    id: string;
    product: string;
    amount: number;
    unit_price: number;
    cost_category: string;
    supplier: string;
    payment_type: string;
    discount: number;
    notes: string;
    subsidiary: string;
    purchaseDate: string;
    parcels: CostParcel[];
}

type FinishedProductOrderInput = Omit<FinishedProductOrder, 'id'>
type FinishedProductOrderCostInput = Omit<FinishedProductOrderCost, 'id'>
type FinishedProductOrderBeneficiationItemInput = Omit<FinishedProductOrderBeneficiationItem, 'id'>
type FinishedProductSaleInput = Omit<FinishedProductSale, 'id'>
type FinishedProductOrderPurchaseInput = Omit<FinishedProductOrderPurchase, 'id'>

interface FinishedProductOrdersProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface FinishedProductOrdersFilt {
    type?: string | string[];
    costCenter?: string | string[];
    subsidiary?: string | string[];
    provider?: string | string[];
    trip?: string | string[];
    paymentType?: string | string[];
    boat?: string | string[];
    status?: string | string[];
    startDate: string;
    endDate: string;
    pages: number;
    limit: number;
}

interface ParcelsData {
    id_array: string[];
    status_array: string[];
    expiration_date_array: string[];
    payment_date_array: string[];
    total_order_value_exec_array: string[];
}

interface PurchaseFilter {
    startDate: string,
    endDate: string,
    provider: string;
    subsidiary: string;
    page: number;
    limit: number;
    is_apportined: boolean;
}

interface StockWithdrawalData {
    id: string;
    item: string;
    allocated_amount: number;
    allocated_at: string;
    recipient: string;
}

type StockWithdrawalDataInput = Omit<StockWithdrawalData, 'id'>

interface StockOutputFilter {
    startDate: string,
    endDate: string,
    product: string;
    page: number;
    limit: number; 
}

interface PaginationFilter {
    page: number;
    limit: number; 
}

interface FinishedProductOrdersContextData {
    getFilteredFinishedProductOrders: (request: FinishedProductOrdersRequest) => Promise<ResponsePropsWithData>;
    createFinishedProductOrder: (newFinishedProductOrder: FinishedProductOrderInput) => Promise<ResponseProps>;
    updateFinishedProductOrder: (finishedProductOrder: FinishedProductOrder) => Promise<ResponseProps>;
    deleteFinishedProductOrder: (id: string) => Promise<ResponseProps>;
    getFinishedProductOrderCosts: (finished_product_order_id: string) => Promise<ResponsePropsWithData>;
    getFinishedProductOrderCost: (id: string) => Promise<ResponsePropsWithData>;
    createFinishedProductOrderCost: (newFinishedProductOrderCost: FinishedProductOrderCostInput) => Promise<ResponseProps>;
    updateFinishedProductOrderCost: (idFinishedProductOrderCost: string, finishedProductOrderCostInput: FinishedProductOrderCostInput) => Promise<ResponseProps>;
    deleteFinishedProductOrderCost: (id: string) => Promise<ResponseProps>;
    getFinishedProductOrderBeneficiationItems: (finished_product_order_id: string) => Promise<ResponsePropsWithData>;
    getFinishedProductOrderBeneficiationItem: (id: string) => Promise<ResponsePropsWithData>;
    createFinishedProductOrderBeneficiationItem: (newFinishedProductOrderBeneficiationItemInput: FinishedProductOrderBeneficiationItemInput) => Promise<ResponseProps>;
    updateFinishedProductOrderBeneficiationItem: (idFinishedProductOrderBeneficiationItem: string, finishedProductOrderBeneficiationItemInput: FinishedProductOrderBeneficiationItemInput) => Promise<ResponseProps>;
    deleteFinishedProductOrderBeneficiationItem: (id: string) => Promise<ResponseProps>;
    getFinishedProductOrderSales: (finished_product_order_id: string) => Promise<ResponsePropsWithData>;
    getFinishedProductOrderSale: (id: string) => Promise<ResponsePropsWithData>;
    createFinishedProductSale: (newFinishedProductSaleInput: FinishedProductSaleInput) => Promise<ResponseProps>;
    updateFinishedProductSale: (idFinishedProductSale: string, finishedProductSaleInput: FinishedProductSaleInput) => Promise<ResponseProps>;
    deleteFinishedProductSale: (id: string) => Promise<ResponseProps>;
    getFinishedProductOrdersFilt: (filter: FinishedProductOrdersFilt) => Promise<ResponsePropsWithData>;
    updateCostParcelsPaymentData: (costParcelsData: ParcelsData) => Promise<ResponseProps>;
    updateSaleParcelsPaymentData: (saleParcelsData: ParcelsData) => Promise<ResponseProps>;
    getFinishedProductOrderPurchases: (filter: PurchaseFilter) => Promise<ResponsePropsWithData>;
    getFinishedProductOrderPurchase: (id: string) => Promise<ResponsePropsWithData>;
    createFinishedProductOrderPurchase: (newFinishedProductOrderPurchase: FinishedProductOrderPurchaseInput) => Promise<ResponseProps>;
    updateFinishedProductOrderPurchase: (idFinishedProductOrderPurchase: string, finishedProductOrderPurchaseInput: FinishedProductOrderPurchaseInput) => Promise<ResponseProps>;
    deleteFinishedProductOrderPurchase: (id: string) => Promise<ResponseProps>;
    updatePurchaseParcelsPaymentData: (purchaseParcelsData: ParcelsData) => Promise<ResponseProps>;
    getFinishedProductOrderStockItems: () => Promise<ResponsePropsWithData>;
    getFinishedProductOrderStockWithdrawals: (id: string) => Promise<ResponsePropsWithData>;
    createFinishedProductOrderStockWithdrawal: (stockWithdrawal: StockWithdrawalDataInput) => Promise<ResponseProps>;
    deleteFinishedProductOrderStockWithdrawal: (id: string) => Promise<ResponseProps>;
    getFinishedProductOrderStockOutputs: (filter: StockOutputFilter) => Promise<ResponsePropsWithData>;
    getFinishedProductOrderStockHistory: (filter: PaginationFilter) => Promise<ResponsePropsWithData>;
}

const FinishedProductOrdersContext = createContext<FinishedProductOrdersContextData>(
    {} as FinishedProductOrdersContextData
);

export function FinishedProductOrdersProvider({ children }: FinishedProductOrdersProvidersProps) {
    async function getFilteredFinishedProductOrders(request: FinishedProductOrdersRequest) {
        let params = {
            ...request,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get('/finished-product-orders', { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar pedidos de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrdersFilt(filter: FinishedProductOrdersFilt) {
        let params = {
            ...filter,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get('/finished-product-orders-filt', { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar pedidos de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductOrder(newFinishedProductOrder: FinishedProductOrderInput) {
        const response = await api.post('/finished-product-orders', newFinishedProductOrder);

        if (response.status === 201) {
            return {
                success: true,
                message: "Pedido de produto acabado adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar pedido de produto acabado."
        }
    }

    async function updateFinishedProductOrder(finishedProductOrder: FinishedProductOrder) {
        const response = await api.put('/finished-product-orders', finishedProductOrder);

        if (response.status === 200) {
            return {
                success: true,
                message: "Pedido de produto acabado editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar pedido de produto acabado."
        }
    }

    async function deleteFinishedProductOrder(id: string) {
        const response = await api.delete(`/finished-product-orders/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Pedido de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover pedido de produto acabado."
        }
    }

    async function getFinishedProductOrderCosts(finished_product_order_id: string) {
        let params = {
            finished_product_order_id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-orders/${finished_product_order_id}/costs`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar custos de pedidos de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderCost(id: string) {
        let params = {
            id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-costs/${id}`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar custo de pedido de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductOrderCost(newFinishedProductOrderCost: FinishedProductOrderCostInput) {
        const response = await api.post('/finished-product-order-costs', newFinishedProductOrderCost);

        if (response.status === 201) {
            return {
                success: true,
                message: "Custo de pedido de produto acabado adicionado com sucesso!",
                id: response.data.id,
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar custo de pedido de produto acabado."
        }
    }

    async function updateFinishedProductOrderCost(idFinishedProductOrderCost: string, finishedProductOrderCostInput: FinishedProductOrderCostInput) {
        const response = await api.put(`/finished-product-order-costs/${idFinishedProductOrderCost}`, finishedProductOrderCostInput);

        if (response.status === 200) {
            return {
                success: true,
                message: "Custo de pedido de produto acabado editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar custo de pedido de produto acabado."
        }
    }

    async function deleteFinishedProductOrderCost(id: string) {
        const response = await api.delete(`/finished-product-order-costs/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Custo de pedido de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover custo de pedido de produto acabado."
        }
    }

    async function getFinishedProductOrderBeneficiationItems(finished_product_order_id: string) {
        let params = {
            finished_product_order_id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-orders/${finished_product_order_id}/items`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar itens de beneficiamento de pedido de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderBeneficiationItem(id: string) {
        let params = {
            id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-items/${id}`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar item de beneficiamento de pedido de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductOrderBeneficiationItem(newFinishedProductOrderBeneficiationItemInput: FinishedProductOrderBeneficiationItemInput) {
        const response = await api.post('/finished-product-order-items', newFinishedProductOrderBeneficiationItemInput);

        if (response.status === 201) {
            return {
                success: true,
                message: "Item de beneficiamento de produto acabado adicionado com sucesso!",
                id: response.data.id,
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar item de beneficiamento de pedido de produto acabado."
        }
    }

    async function updateFinishedProductOrderBeneficiationItem(idFinishedProductOrderBeneficiationItem: string, finishedProductOrderBeneficiationItemInput: FinishedProductOrderBeneficiationItemInput) {
        const response = await api.put(`/finished-product-order-items/${idFinishedProductOrderBeneficiationItem}`, finishedProductOrderBeneficiationItemInput);

        if (response.status === 200) {
            return {
                success: true,
                message: "Item de beneficiamento de pedido de produto acabado editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar item de beneficiamento de pedido de produto acabado."
        }
    }

    async function deleteFinishedProductOrderBeneficiationItem(id: string) {
        const response = await api.delete(`/finished-product-order-items/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Item de beneficiamento de pedido de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover item de beneficiamento de pedido de produto acabado."
        }
    }

    async function getFinishedProductOrderSales(finished_product_order_id: string) {
        let params = {
            finished_product_order_id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-orders/${finished_product_order_id}/sales`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar vendas de pedido de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderSale(id: string) {
        let params = {
            id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-sales/${id}`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar venda de pedido de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductSale(newFinishedProductSaleInput: FinishedProductSaleInput) {
        const response = await api.post('/finished-product-order-sales', newFinishedProductSaleInput);

        if (response.status === 201) {
            return {
                success: true,
                message: "Venda de produto acabado adicionada com sucesso!",
                id: response.data.id,
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar venda de produto acabado."
        }
    }

    async function updateFinishedProductSale(idFinishedProductSale: string, finishedProductSaleInput: FinishedProductSaleInput) {
        const response = await api.put(`/finished-product-order-sales/${idFinishedProductSale}`, finishedProductSaleInput);

        if (response.status === 200) {
            return {
                success: true,
                message: "Venda de produto acabado editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar venda de produto acabado."
        }
    }

    async function deleteFinishedProductSale(id: string) {
        const response = await api.delete(`/finished-product-order-sales/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Venda de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover venda de produto acabado."
        }
    }

    async function updateCostParcelsPaymentData(costParcelsData: ParcelsData) {
        const response = await api.put('/finished-product-order-costs-parcels', costParcelsData);

        if (response.status === 200) {
            return {
                success: true,
                message: "Dados de pagamento de parcelas de custo de pedido de produto acabado alterado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao alterar dados de pagamento de parcelas de custo de pedido de produto acabado."
        }
    }

    async function updateSaleParcelsPaymentData(saleParcelsData: ParcelsData) {
        const response = await api.put('/finished-product-order-sales-parcels', saleParcelsData);

        if (response.status === 200) {
            return {
                success: true,
                message: "Dados de pagamento de parcelas de venda de pedido de produto acabado alterado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao alterar dados de pagamento de parcelas de venda de pedido de produto acabado."
        }
    }

    async function getFinishedProductOrderPurchase(id: string) {
        let params = {
            id,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-purchases/${id}`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar compra para pedido de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderPurchases(filter: PurchaseFilter) {
        let params = {
            ...filter,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-purchases`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar compras para pedidos de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductOrderPurchase(newFinishedProductOrderpurchase: FinishedProductOrderPurchaseInput) {
        const response = await api.post('/finished-product-order-purchases', newFinishedProductOrderpurchase);

        if (response.status === 201) {
            return {
                success: true,
                message: "Compra para pedido de produto acabado adicionado com sucesso!",
                id: response.data.id,
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar compra para pedido de produto acabado."
        }
    }

    async function updateFinishedProductOrderPurchase(idFinishedProductOrderPurchase: string, finishedProductOrderPurchaseInput: FinishedProductOrderPurchaseInput) {
        const response = await api.put(`/finished-product-order-purchases/${idFinishedProductOrderPurchase}`, finishedProductOrderPurchaseInput);

        if (response.status === 200) {
            return {
                success: true,
                message: "Compra para pedido de produto acabado editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar compra para pedido de produto acabado."
        }
    }

    async function deleteFinishedProductOrderPurchase(id: string) {
        const response = await api.delete(`/finished-product-order-purchases/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Compra para pedido de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover compra para pedido de produto acabado."
        }
    }

    async function updatePurchaseParcelsPaymentData(purchaseParcelsData: ParcelsData) {
        const response = await api.put('/finished-product-order-purchase-parcels', purchaseParcelsData);

        if (response.status === 200) {
            return {
                success: true,
                message: "Dados de pagamento de parcelas de compra para pedido de produto acabado alterado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao alterar dados de pagamento de parcelas de compra para pedido de produto acabado."
        }
    }
    
    async function getFinishedProductOrderStockItems() {
        let params = {
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-stock-items`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar itens de estoque para pedidos de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderStockWithdrawals(id: string) {
        let params = {
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-orders/${id}/stock-withdrawal`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar itens de estoque para pedidos de produto acabado.",
            data: [],
        }
    }

    async function createFinishedProductOrderStockWithdrawal(stockWithdrawal: StockWithdrawalDataInput) {
        const response = await api.post('/finished-product-order-stock-withdrawal', stockWithdrawal);

        if (response.status === 201) {
            return {
                success: true,
                message: "Retirada de item de estoque para pedido de produto acabado adicionada com sucesso!",
                id: response.data.id,
            }
        }
        return {
            success: false,
            message: "Erro ao retirar item de estoque para pedido de produto acabado."
        }
    }
    
    async function deleteFinishedProductOrderStockWithdrawal(id: string) {
        const response = await api.delete(`/finished-product-order-stock-withdrawal/${id}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Item de estoque para pedido de produto acabado removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover item de estoque para pedido de produto acabado."
        }
    }

    async function getFinishedProductOrderStockOutputs(filter: StockOutputFilter) {
        let params = {
            ...filter,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-stock-withdrawal`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar movimentações de item do estoque para pedidos de produto acabado.",
            data: [],
        }
    }

    async function getFinishedProductOrderStockHistory(filter: PaginationFilter) {
        let params = {
            ...filter,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get(`/finished-product-order-stock-withdrawal-logs`, { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar o histórico do estoque para pedidos de produto acabado.",
            data: [],
        }
    }

    return (
        <FinishedProductOrdersContext.Provider value={{
            getFilteredFinishedProductOrders,
            getFinishedProductOrdersFilt,
            createFinishedProductOrder,
            updateFinishedProductOrder,
            deleteFinishedProductOrder,
            createFinishedProductOrderCost,
            updateFinishedProductOrderCost,
            deleteFinishedProductOrderCost,
            getFinishedProductOrderCosts,
            getFinishedProductOrderCost,
            getFinishedProductOrderBeneficiationItems,
            getFinishedProductOrderBeneficiationItem,
            createFinishedProductOrderBeneficiationItem,
            updateFinishedProductOrderBeneficiationItem,
            deleteFinishedProductOrderBeneficiationItem,
            getFinishedProductOrderSales,
            getFinishedProductOrderSale,
            createFinishedProductSale,
            updateFinishedProductSale,
            deleteFinishedProductSale,
            updateCostParcelsPaymentData,
            updateSaleParcelsPaymentData,
            getFinishedProductOrderPurchases,
            getFinishedProductOrderPurchase,
            createFinishedProductOrderPurchase,
            updateFinishedProductOrderPurchase,
            deleteFinishedProductOrderPurchase,
            updatePurchaseParcelsPaymentData,
            getFinishedProductOrderStockItems,
            getFinishedProductOrderStockWithdrawals,
            createFinishedProductOrderStockWithdrawal,
            deleteFinishedProductOrderStockWithdrawal,
            getFinishedProductOrderStockOutputs,
            getFinishedProductOrderStockHistory,
        }}>
            {children}
        </FinishedProductOrdersContext.Provider>
    );
}

export function UseFinishedProductOrders() {
    return useContext(FinishedProductOrdersContext);
}