import { useEffect, useState, useContext } from "react";
import Button from "../../../../components/Button";
import {
  Container,
  Header,
  Row,
  Content,
  ListTitle,
  Divider,
  Explanation,
} from "./styles";
import Modal from "@mui/material/Modal";
import { Close } from "../../../../assets/icons/index";
import Spinner from "../../../../components/Spinner";
import Datepicker from "../../../../components/Datepicker";
import Textfield from "../../../../components/Textfield";
import { UseCostCenters } from "../../../../hooks/useCostCenters";
import { UseTrips } from "../../../../hooks/useTrips";
import { UseBoats } from "../../../../hooks/useBoats";
import { UseEmployees } from "../../../../hooks/useEmployees";
import moment from "moment";
import Autocomplete from "../../../../components/Autocomplete";
import Numberfield from "../../../../components/Numberfield";
import AppContext from "../../../../state/App.context";
import LocalStorageService from "../../../../services/storage";
import { UseStock } from "../../../../hooks/useStock";

export const harvestOptions = [
  {
    id: 2023,
    label: "2023",
  },
  {
    id: 2024,
    label: "2024",
  },
  {
    id: 2025,
    label: "2025",
  },
];

export default function ModalTakeFromStock({
  open,
  selectedItem,
  setSelectedItem,
  setOpenModalTakeFromStock,
  width,
  height,
}) {
  const { costCenters } = UseCostCenters();
  const { trips } = UseTrips();
  const { boats } = UseBoats();
  const { employees } = UseEmployees();
  const { takeItensFromStock } = UseStock();
  const localStorageService = LocalStorageService();

  const [amount, setAmount] = useState(selectedItem?.item_amount || 0);
  const [costCenter, setCostCenter] = useState({ id: 0 });
  const [trip, setTrip] = useState({ id: 0 });
  const [createdAt, setCreatedAt] = useState("");
  const [notes, setNotes] = useState("");
  const [employee, setEmployee] = useState({ id: 0 });
  const [harvest, setHarvest] = useState({ id: 0 });
  const fontSize = 12;
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  let employeesOptions =
    employees &&
    !employees.error &&
    employees.map((element) => {
      return {
        id: element?.id_employee,
        label: element?.full_name,
      };
    });

  const title = "Retirar itens do estoque";

  function cleanAllInputs() {
    setAmount(0);
    setCostCenter({ id: 0 });
    setTrip({ id: 0 });
    setCreatedAt("");
    setEmployee({ id: 0 });
    setNotes("");
  }

  function handleClose() {
    setOpenModalTakeFromStock(false);
    setSelectedItem({});
    cleanAllInputs();
  }

  let costCentersOptions =
    costCenters &&
    !costCenters.error &&
    costCenters
      .filter((element) => element.isactive)
      .map((element) => {
        return {
          id: element?.id_cost,
          label: element?.costs_center_name,
        };
      });

  function getBoatName(boatId) {
    try {
      let index = boats.findIndex((boat) => boat.id_boat === boatId);
      if (index !== -1) {
        return boats[index].boats_name;
      } else return "";
    } catch (err) {
      console.log(err);
    }
  }

  let tripsOptions =
    trips &&
    !trips?.error &&
    trips.map((element) => {
      return {
        id: element?.id_trip,
        label:
          element?.trips_name + " - " + getBoatName(element?.fk_boats_id_boat),
        harvest: element?.harvest,
      };
    });

  if (harvest.id) {
    tripsOptions = tripsOptions.filter(
      (element) => element.harvest === harvest?.label
    );
  }

  function checkingRequiredFields() {
    if (
      !costCenter.id ||
      !trip.id ||
      !amount ||
      !employee?.id ||
      createdAt === ""
    ) {
      return false;
    }
    return true;
  }

  function checkingAmount() {
    if (parseInt(amount) > parseInt(selectedItem?.item_amount)) {
      return false;
    }
    return true;
  }

  async function handleTakeFromStock(event) {
    try {
      setLoading(true);
      event.preventDefault();
      if (checkingRequiredFields()) {
        if (checkingAmount()) {
          const userInfo = localStorageService.getUserInfo();
          const id_user = userInfo?.id_user;
          const transactionToEdit = {
            id_transaction: selectedItem?.id_transaction,
            output_amount: parseInt(amount),
            fk_costs_center_id_cost: costCenter?.id,
            fk_trips_id_trip: trip?.id,
            employee: employee?.id || null,
            notes,
            created_at: createdAt,
            id_order: selectedItem?.id_order,
            id_user,
          };
          const response = await takeItensFromStock(transactionToEdit);
          if (response.success) {
            handleClose();
            cleanAllInputs();
            setSnack({
              open: true,
              severity: "success",
              message: "Operação realizada com sucesso!",
            });
          } else {
            setSnack({
              open: true,
              severity: "error",
              message:
                "Ocorreu um erro ao retirar insumo do estoque: " +
                response.message,
            });
          }
        } else {
          setSnack({
            open: true,
            severity: "error",
            message:
              "A quantidade que deseja retirar é maior do que a disponível no estoque!",
          });
        }
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: "Preencha todos os campos necessários!",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return <Spinner width={40} fontSize={14}></Spinner>;
    } else {
      return (
        <Content>
          <Row>
            <ListTitle>Informações do item:</ListTitle>
          </Row>
          <Divider></Divider>
          <Row>
            <h4>
              Item: <b>{selectedItem?.item_name} </b>
            </h4>
            <h4>
              Quantidade total: <b> {selectedItem?.item_amount} </b>
            </h4>
          </Row>
          <Row>
            <h4>
              Fornecedor: <b>{selectedItem?.providers_name}</b>
            </h4>
            <h4>
              Subsidiária: <b>{selectedItem?.subsidiary_name}</b>
            </h4>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <ListTitle>Dados da retirada:</ListTitle>
          </Row>
          <Divider></Divider>
          <Row>
            <Datepicker
              label="Data de criação*"
              width={"95%"}
              disabled={false}
              value={createdAt}
              fontSize={fontSize}
              onChange={setCreatedAt}
            />
            <Numberfield
              label="Quantidade*"
              width={"95%"}
              disabled={false}
              value={amount}
              fontSize={fontSize}
              onChange={setAmount}
            ></Numberfield>
            <Autocomplete
              value={costCenter}
              width={"95%"}
              disabled={false}
              options={costCentersOptions}
              fontSize={fontSize}
              label="Centro de custo*"
              onChange={setCostCenter}
            ></Autocomplete>
          </Row>
          <Row>
            <Autocomplete
              value={harvest}
              width="95%"
              disabled={false}
              options={harvestOptions}
              fontSize={fontSize}
              label="Safra*"
              onChange={setHarvest}
            ></Autocomplete>
            <Autocomplete
              value={costCenter}
              width="95%"
              disabled={false}
              options={tripsOptions}
              fontSize={fontSize}
              label="Viagem*"
              onChange={setTrip}
            ></Autocomplete>
          </Row>
          <Row>
            <Autocomplete
              value={employee}
              width={"100%"}
              disabled={false}
              options={employeesOptions}
              fontSize={fontSize}
              label="Receptor*"
              onChange={setEmployee}
            ></Autocomplete>
          </Row>
          <Row>
            <Textfield
              label="Observações"
              width={"100%"}
              disabled={false}
              value={notes}
              fontSize={fontSize}
              onChange={setNotes}
              multiline={true}
            ></Textfield>
          </Row>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleClose()}
            ></Button>
            <Button
              label="Retirar itens"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleTakeFromStock}
            ></Button>
          </Row>
        </Content>
      );
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  );
}
