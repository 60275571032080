import styled from 'styled-components';

export const Container = styled.div`
    background: var(--background);
    overflow-y: auto;
    width: calc(100% - 5rem);
    padding: 24px;
    scrollbar-width: thin; 
    scrollbar-color: #989898 #FFFFFF; 
    
    &::-webkit-scrollbar {
        width: 6px; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #989898; 
    border-radius: 4px; 
    }

    &::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }
`;

export const Content = styled.div`
    height: auto;
    width: 100%;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
`;

export const Row = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const RowFilters = styled.div`
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

export const RowContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const RowPagination = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 16px;

    h1{
        color: var(--txt-subtitle);
        font-size: 0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
        font-weight: regular;
    }

    img {
        cursor: pointer;
    }
`;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 160%;
    color: var(--txt-title);
    text-align: left;
` ;
