import React, { useState, useContext, useEffect } from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
  Container, Content, Row
} from './styles';
import { UseProviders } from "../../../hooks/useProviders"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddSeller from './ModalAddSeller/index';
import ModalEditSeller from './ModalEditSeller/index';
import ModalRemoveSeller from './ModalRemoveSeller/index';
import AppContext from "../../../state/App.context";

const columns = [
  {
    name: 'Nome',
    key: 'providers_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Descrição',
    key: 'description',
    type: 'string',
    unit: '',
  },
]

function Sellers() {
  const { providers } = UseProviders();
  const [search, setSearch] = useState('');
  const [openModalAddSeller, setOpenModalAddSeller] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState({ open: false, mode: '', providers_name: '', id_providers: null });
  const [, setSnack] = useContext(AppContext).snackState;

  let sellersToShow = providers && !providers.error && providers.filter(a => a.type === 'Vendedor' && a.isactive);
  
  if (search.length > 0) {
    sellersToShow = sellersToShow.filter(a => a.providers_name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(() => {
    if (providers.error) {
      setSnack({
        open: true,
        severity: 'error',
        message: providers.error,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers])

  return (
    <Container>
      <Header title="Vendedores" subtitle="Gerencie os vendedores cadastrados" />
      <Content>
        <Row>
          <Button
            label="Adicionar vendedor"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => setOpenModalAddSeller(true)}
          ></Button>
          <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar vendedor..." fontSize={'0.7rem'} ></Searchbar>
        </Row>
        <Table
          columns={columns}
          rows={sellersToShow || []}
          hasEditing={true}
          hasRemoving={true}
          setSelectedItem={setSelectedSeller}
        ></Table>
      </Content>
      <ModalAddSeller
        open={openModalAddSeller}
        handleOpen={setOpenModalAddSeller}
        width={700}
        height={330}
      ></ModalAddSeller>
      <ModalEditSeller
        open={selectedSeller.open && selectedSeller.mode === 'edit'}
        setSelectedItem={setSelectedSeller}
        selectedItem={selectedSeller}
        width={700}
        height={330}
      ></ModalEditSeller>
      <ModalRemoveSeller
        open={selectedSeller.open && selectedSeller.mode === 'remove'}
        setSelectedItem={setSelectedSeller}
        providerName={selectedSeller?.providers_name}
        providerId={selectedSeller?.id_providers}
        width={600}
        height={170}
      ></ModalRemoveSeller>
    </Container>
  );
};

export default React.createElement(Sellers);