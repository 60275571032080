import { useState, useEffect, useContext } from 'react';
import { Container, Header, Row, Content, ListTitle, Divider, ContainerTab, Tabs } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Table from '../../../components/Table';
import moment from 'moment';
import { formatMoney, formatNumber } from '../../../services/functions';
import { UseOrders } from '../../../hooks/useOrders';
import { UseFiles } from "../../../hooks/useFiles";
import AppContext from "../../../state/App.context";
import Button from "../../../components/Button";
import { columnsParcels, columnsTransactions, columnsOutputs, tabsStates, columnsBeneficiationItems, columnsCostsAndExpenses, columnsFiles, columnsRawMaterials, columnsSales, columnsStock } from './options';
import Spinner from "../../../components/Spinner";
import { UseFinishedProductOrders } from '../../../hooks/useFinishedProductOrders';
import { UseCostCategories } from '../../../hooks/useCostCategories';
import { UseProducts } from '../../../hooks/useProducts';
import { UseProviders } from '../../../hooks/useProviders';

export default function ModalVisualizeFinishedProductOrder({ open, setSelectedItem, width, height, selectedItem }) {
  const { getOrderTransactions, getOrderDetails } = UseOrders();
  const { getFinishedProductOrderCosts, getFinishedProductOrderBeneficiationItems, getFinishedProductOrderSales } = UseFinishedProductOrders();
  const { getOrderFiles, downloadFile } = UseFiles();

  const { costCategories } = UseCostCategories();
  const { products } = UseProducts();
  const { providers } = UseProviders();

  const [transactions, setTransactions] = useState([]);
  const [, setSnack] = useContext(AppContext).snackState;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(tabsStates[0].id);

  const [order, setOrder] = useState(null);
  const [costsAndExpensesList, setCostsAndExpensesList] = useState([]);
  const [beneficiationItemsList, setBeneficiationItemsList] = useState([]);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [stockList, setStockList] = useState([]);

  let finishedProductOrder = selectedItem?.item;

  useEffect(() => {
    async function getInputOrder() {
      try {
        setLoading(true);
        const orderFromServer = await getOrderDetails(finishedProductOrder.id_order);

        if (orderFromServer && orderFromServer.success && orderFromServer.data && orderFromServer.data.length > 0) {
          setOrder(orderFromServer.data[0]);
        }

        let activetransactions;
        const transactionsFromServer = await getOrderTransactions(finishedProductOrder.id_order);

        if (transactionsFromServer && transactionsFromServer.success && transactionsFromServer.data && transactionsFromServer.data.length > 0) {
          activetransactions = transactionsFromServer?.data.filter(element => element.isactive);
          setTransactions(activetransactions);
        }

        const items = [];
        const stockItems =  [];
        const responseBeneficiationItems = await getFinishedProductOrderBeneficiationItems(selectedItem.item?.id_finished_product_order);

        if (responseBeneficiationItems && responseBeneficiationItems.success && responseBeneficiationItems.data && responseBeneficiationItems.data.length > 0) {
          responseBeneficiationItems?.data?.forEach((beneficiationItem) => {
            beneficiationItem.items?.forEach((item) => {
              const productName = products.find((product) => product.id_product === item.finished_product)?.product_name;

              items.push({
                id: item.id,
                finished_product_name: productName,
                produced_amount: item.produced_amount,
                beneficiation_yield: formatNumber(100.0 * item.produced_amount / (item.raw_material_amount - item.weakfish)) + '%',
              });

              stockItems.push({
                finished_product_name: productName,
                stock_amount: item.produced_amount - item.withdrawn_amount,
                withdrawn_amount: item.withdrawn_amount,
              });
            });
          });

          setBeneficiationItemsList(items);
          setStockList(stockItems);
        }

        const rawMaterials = [];

        activetransactions?.forEach(transaction => {
          let totalAmountProcessed = 0;
          
          for (const bItem of responseBeneficiationItems?.data) {
            totalAmountProcessed += bItem.items?.filter((item) => item.raw_material_transaction === transaction.id_transaction)?.reduce((acumulator, itemReduced) => acumulator + itemReduced.raw_material_amount, 0) ?? 0;
          }

          rawMaterials.push({
            raw_material_name: transaction.product_name,
            amount: transaction.product_amount,
            unit_price: transaction.unity_price,
            total_value: transaction.product_amount * transaction.unity_price,
            cost_category_name: costCategories.find((costCategory) => costCategory.id_category === transaction.fk_costs_categories_id_category)?.costs_categories_name,
            balance_to_be_processed: transaction.product_amount - totalAmountProcessed,
          });
        });

        setRawMaterialsList(rawMaterials);
        let responseSales = await getFinishedProductOrderSales(selectedItem.item?.id_finished_product_order);

        if (responseSales.success) {
          const sales = responseSales?.data?.map(sale => ({
            dt_sale: sale.dt_sale,
            provider_name: providers.find((provider) => provider.id_providers === sale.provider).providers_name,
            seller_name: providers.find((provider) => provider.id_providers === sale.seller).providers_name,
            finished_product_order_item_name: items?.find((item) => item.id === sale.finished_product_order_item).finished_product_name,
            amount: sale.amount,
            unit_price: sale.unit_price,
            total_value: sale.amount * sale.unit_price,
            cost_category_name: costCategories.find((costCategory) => costCategory.id_category === sale.cost_category).costs_categories_name,
          }));

          setSalesList(sales);
        }

        const costsList = [];
        const totalAmountRawMaterial = activetransactions?.reduce((total, transaction) => total + Number(transaction.product_amount), 0);
        const costsFromServer = await getFinishedProductOrderCosts(finishedProductOrder.id_finished_product_order);

        if (costsFromServer && costsFromServer.success && costsFromServer.data && costsFromServer.data.length > 0) {
          costsFromServer.data.forEach((cost) => (
            cost.items.forEach((item) => (
              costsList.push({
                product_name: products.find((product) => product.id_product === item.product)?.product_name,
                amount: item.amount,
                unit_price: item.unit_price,
                total_value: item.amount * item.unit_price,
                cost_category_name: costCategories.find((costCategory) => costCategory.id_category === item.cost_category)?.costs_categories_name,
                cost_per_kg: totalAmountRawMaterial ? ((item.amount * item.unit_price) / totalAmountRawMaterial) : '-',
              })
            ))
          ));
        }

        setCostsAndExpensesList([...costsList]);
      } finally {
        setLoading(false);
      }
    }

    getInputOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let totalExpenses = costsAndExpensesList.reduce((total, item) => total + Number(item.total_value), 0);
  let totalRawMaterialCosts = rawMaterialsList.reduce((total, item) => total + Number(item.total_value), 0);
  let totalBilled = salesList.reduce((total, item) => total + Number(item.total_value), 0);
  let totalKgSold = salesList.reduce((total, item) => total + Number(item.amount), 0);

  let parcels = [];

  if (order?.amount_parcels && order?.expiration_date) {
    for (let i = 0; i < order.amount_parcels; i++) {
      parcels.push(
        {
          name: i + 1,
          expiration_date: order?.expiration_date[i],
          payment_date: order?.status[i] === 'Executado' ? order?.payment_date[i] : '',
          status: order?.status[i],
          price: order?.values_parcels[i],
        }
      )
    }
  }

  let totalOutputs = 0;

  if (order?.saidas_estoque && order?.saidas_estoque.length > 0) {
    order?.saidas_estoque.forEach((output) => {
      totalOutputs = totalOutputs + output?.output_value;
    })
  }

  function extractFileInfo(filename) {
    let firstUnderscoreIndex = filename.indexOf('_');
    let id = filename.slice(0, firstUnderscoreIndex);
    let name = filename.slice(firstUnderscoreIndex + 1);

    return {
      id: id,
      name: name,
    };
  }

  async function getFilesInfo() {
    try {
      let responseFiles = await getOrderFiles(order?.id_order);

      if (responseFiles.success) {
        let filesFormatted = [];

        responseFiles?.data.forEach((oldFile) => {
          const { name, id } = extractFileInfo(oldFile);

          filesFormatted.push({
            name,
            id,
            filename: oldFile,
          })
        });

        setFiles(filesFormatted);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: responseFiles?.message,
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (order && order.id_order) {
      getFilesInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  async function handleDownloadFile(selectedItem) {
    try {
      const filename = selectedItem.item.filename;
      const name = selectedItem.item.name;
      const response = await downloadFile(filename, name);

      if (response.success) {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Baixando arquivo ' + name + '...',
        });
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Erro ao baixar o arquivo ' + name + '!',
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderParcels() {
    if (order && order.amount_parcels) {
      return (
        <>
          <Row style={{ marginBottom: 8 }}>
            <h4><b>Parcelas:</b></h4>
          </Row>
          <Table
            columns={columnsParcels}
            rows={parcels || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="160px"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
        </>
      )
    } else {
      return (
        <Row>
          <h4><b>Data de vencimento:</b> {order.expiration_date ? moment(order?.expiration_date[0]).add(3, 'hours').format('DD/MM/YYYY') : ''}</h4>
          <h4><b>Data de pagamento:</b> {order.status ? order?.status[0] === 'Executado' ? moment(order?.payment_date[0]).add(3, 'hours').format('DD/MM/YYYY') : '-' : ''}</h4>
          <h4><b>Status:</b> {order.status ? order?.status[0] : ''}</h4>
        </Row>
      )
    }
  }

  function renderOutputs() {
    if (order && order.saidas_estoque) {
      return (
        <>
          <Row>
            <ListTitle>Movimentações do estoque:</ListTitle>
          </Row>
          <Divider></Divider>
          <Table
            columns={columnsOutputs}
            rows={order.saidas_estoque || []}
            hasEditing={false}
            hasRemoving={false}
            setSelectedItem={() => { }}
            height="100%"
            actionsInFirstLine={false}
            fitWidth={false}
          ></Table>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderUpdatedValue() {
    if (order && order.total_order_value_updated) {
      return (
        <>
          <Row>
            <h4 style={{ width: '100%' }}><b>Valor total de retiradas:</b> {totalOutputs ? formatMoney(totalOutputs) : ''} </h4>
          </Row>
          <Row>
            <h4 style={{ width: '100%' }}><b>Valor atualizado do pedido:</b> {order?.total_order_value_updated ? formatMoney(order?.total_order_value_updated) + ' (valor utilizado nos cálculos)' : ''} </h4>
          </Row>
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderCostCenterAndTrip() {
    if (order?.category !== 'Estoque' && order?.category !== 'Rateio') {
      return (
        <Row>
          <h4><b>Viagem/Safra:</b> {order?.trips_name}/{order?.harvest}</h4>
          <h4><b>Barco:</b> {order?.boats_name}</h4>
          <h4><b>Centro de custo:</b> {order?.costs_center_name}</h4>
        </Row>
      )
    } else {
      return (
        <></>
      )
    }
  }

  async function goToOriginalOrder() {
    try {
      setLoading(true);
      let responseOriginalOrder = await getOrderDetails(order?.original_order);

      if (responseOriginalOrder.success) {
        setSelectedItem({ ...selectedItem, item: responseOriginalOrder?.data[0] });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderButtonOriginalOrder() {
    if (order && order.original_order) {
      return (
        <Button
          label="Ver pedido original"
          background="#256CE1"
          color="white"
          borderColor="#256CE1"
          disabled={false}
          onClick={() => { goToOriginalOrder() }}
        ></Button>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function renderPaymentInfo() {
    if (order?.original_order) {
      return (
        <>
          <Row>
            <h4><b>Valor total do pedido:</b> {order.total_order_value ? formatMoney(order?.total_order_value) : ''}</h4>
            <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name || ''}</h4>
            <h4><b>É custo indireto?:</b> {order?.is_indirect_cost ? 'Sim' : 'Não'}</h4>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <h4><b>Valor total original:</b> {order.total_order_value ? formatMoney(order?.total_order_value) : '-'}</h4>
            <h4><b>Desconto:</b> {order.discount ? formatMoney(order?.discount) : ''}</h4>
            <h4><b>Valor total original com desconto:</b> {formatMoney(order?.total_order_value - order?.discount)}</h4>
          </Row>
          <Row>
            <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name || ''}</h4>
            <h4><b>Qtd. de parcelas:</b> {order.amount_parcels ? parseInt(order?.amount_parcels) : '0'}</h4>
            <h4><b>Recibo:</b> {order?.receipt || 'Sem recibo'}</h4>
          </Row>
          {renderParcels()}
          <Row>
            <h4><b>É custo indireto?:</b> {order?.is_indirect_cost ? 'Sim' : 'Não'}</h4>
          </Row>
        </>
      )
    }
  }

  function renderInputOrder() {
    return (
      <Content>
        <Row>
          <h4 style={{ width: '100%' }}><b>Id do pedido:</b> {order?.id_order}</h4>
        </Row>
        <Row>
          <h4><b>Subsidiária:</b> {order?.subsidiary_name}</h4>
          <h4><b>{order?.type === 'Entrada' ? 'Comprador' : 'Fornecedor'}</b>: {order?.providers_name}</h4>
          <h4><b></b></h4>
        </Row>
        {renderCostCenterAndTrip()}
        <Row >
          <h4><b>Criado em:</b> {moment(order?.created_at).format('DD/MM/YYYY [às] HH:mm')}</h4>
          <h4><b>Criado por:</b> {order?.created_by}</h4>
          <h4><b>Aprovado por:</b> {order?.approved === true ? order?.approved_by : 'Não aprovado'}</h4>
        </Row>
        <Row style={{ marginBottom: 8 }}>
          <h4><b>Observações:</b>{order?.notes || ' Sem observações'}</h4>
        </Row>
        <Row>
          <ListTitle>Produtos:</ListTitle>
        </Row>
        <Divider></Divider>
        <Table
          columns={columnsTransactions}
          rows={transactions || []}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={() => { }}
          actionsInFirstLine={false}
          height="100%"
          fitWidth={false}
        ></Table>
        <Row>
          <ListTitle>Pagamento:</ListTitle>
        </Row>
        <Divider></Divider>
        {order && renderPaymentInfo()}
        <br></br>
        {renderOutputs()}
        {renderUpdatedValue()}
        <Row>
          <ListTitle>Anexos salvos</ListTitle>
        </Row>
        <Divider></Divider>
        <Row style={{ marginTop: 8, marginBottom: 8 }}>
          <h1> Clique no arquivo para realizar o download:</h1>
        </Row>
        <Table
          columns={columnsFiles}
          rows={files || []}
          hasEditing={false}
          hasRemoving={false}
          setSelectedItem={handleDownloadFile}
          height="auto"
        ></Table>
        <br></br>
        <Row style={{ width: '100%', justifyContent: 'center' }}>
          {renderButtonOriginalOrder()}
        </Row>
      </Content>
    );
  }

  function renderBeneficiation() {
    return (
      <Content>
        <Row><ListTitle>Custos e Despesas</ListTitle></Row>
        <Row></Row>
        <Row>
          <h4 style={{ width: 'fit-content' }}><b>Total de despesas:</b> {formatMoney(totalExpenses - totalRawMaterialCosts)}</h4>
          <h4 style={{ width: 'fit-content' }}><b>Total de custos com matéria-prima:</b> {formatMoney(totalRawMaterialCosts)}</h4>
          <h4 style={{ width: 'fit-content' }}><b>Total de custos:</b> {formatMoney(totalExpenses)}</h4>
        </Row>
        <Row></Row>
        <Table
          columns={columnsCostsAndExpenses}
          rows={costsAndExpensesList}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
        <Divider></Divider>
        <Row><ListTitle>Matérias-primas</ListTitle></Row>
        <Table
          columns={columnsRawMaterials}
          rows={rawMaterialsList}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
        <Divider></Divider>
        <Row><ListTitle>Produtos Acabados Pós-beneficiamento</ListTitle></Row>
        <Table
          columns={columnsBeneficiationItems}
          rows={beneficiationItemsList}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
      </Content>
    );
  }

  function renderStock() {
    return (
      <Content>
        <Row></Row>
        <Row>
          <h4 style={{ width: 'fit-content' }}><b>Total faturado:</b> {formatMoney(totalBilled)}</h4>
          <h4 style={{ width: 'fit-content' }}><b>Resultado atual:</b> {formatMoney(totalBilled - totalExpenses)}</h4>
          <h4 style={{ width: 'fit-content' }}><b>Total de kg vendido:</b> {formatNumber(totalKgSold)} kg</h4>
          <h4 style={{ width: 'fit-content' }}><b>Resultado atual/kg:</b> {(totalKgSold > 0) ? (formatNumber((totalBilled - totalExpenses) / totalKgSold).toString() + ' R$/kg') : '-'}</h4>
        </Row>
        <Row></Row>
        <Table
          columns={columnsStock}
          rows={stockList}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
      </Content>
    );
  }

  function renderSales() {
    return (
      <Content>
        <Row></Row>
        <Table
          columns={columnsSales}
          rows={salesList || []}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
      </Content>
    );
  }

  function renderTable() {
    switch (tab) {
      case 'input-order':
        return renderInputOrder();
      case 'beneficiation':
        return renderBeneficiation();
      case 'sales':
        return renderSales();
      case 'stock':
        return renderStock();
      default:
        return null;
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    }

    return (
      <ContainerTab style={{ height: 'auto', justifyContent: 'flex-start' }}>
        <Row><ListTitle>Id do pedido de produto acabado: {selectedItem.item.id_finished_product_order}</ListTitle></Row>
        <Divider></Divider>
        <Row style={{ display: 'flex', marginTop: 0, width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Row>
              <Tabs>
                {
                  tabsStates.map((state, index) => {
                    if (state.id === tab) {
                      return (
                        <h4 style={{
                          color: '#256CE1', borderBottom: '1px solid #256CE1', fontWeight: 600
                        }} onClick={() => setTab(state.id)} key={index}>{state?.label}</h4>
                      )
                    }
                    return (
                      <h4 onClick={() => setTab(state.id)} key={index}>{state?.label}</h4>
                    )
                  })
                }
              </Tabs>
            </Row>
            <Row style={{ marginTop: 8 }}>
              {renderTable()}
            </Row>
          </div>
        </Row>
      </ContainerTab>
    )
  }

  return (
    <Modal
      open={open}
      onClose={() => setSelectedItem({ open: false, mode: 'view', name: '', id_order: null })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            Detalhes do Pedido de Produto Acabado
          </h1>
          <img src={Close} onClick={() => setSelectedItem({ open: false, mode: 'view', name: '', id_order: null })} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}