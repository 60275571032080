

import { Container, Label} from './styles';
import TextField from '@mui/material/TextField';
import {Calendar} from '../../assets/icons/index';
import moment from 'moment';

interface DatePickerProps{
    label: string;
    width: number,
    disabled: boolean;
    value: string;
    fontSize: number;
    type: string;
    onChange: (arg0: string) => void;
}


function addThreeHours(dateString: string) {
    const originalDate = moment(dateString);
    const newDate = originalDate.add(3, 'hours');
    const newDateString = newDate.toISOString();
    return newDateString;
  }

export default function CustomDatepicker({ label, width, disabled, fontSize, onChange, value, type}: DatePickerProps){
    let valueToShow = addThreeHours(value);
    if(type === 'datetime-local'){
        valueToShow = moment(valueToShow).format('YYYY-MM-DDTHH:mm')
    }
    return (
        <Container>
            <Label style={{fontSize:fontSize}}>{label}:</Label>
            <TextField  
                value={type === 'datetime-local' ? valueToShow : moment(valueToShow).format('YYYY-MM-DD')}
                type={type || 'date'}
                variant="outlined" 
                size="small"
                disabled={disabled}
                onChange={(event)=> onChange(event.target.value)}
                style={{
                    width: width,
                    color: '#141522',
                    background:'#FFFFFF',
                    fontSize: fontSize
                    }} 
                sx={{ 
                    borderRadius:'10px', 
                    border: '0.2px solid #8E92BC',
                    '& input': {
                                color: '#141522',
                                fontFamily:'Plus Jakarta Sans',
                                fontSize:fontSize,
                            }
                    }}
                InputProps={{
                    startAdornment: (
                        <img src={Calendar} alt="calendar"  style={{marginRight:8}}></img>
                    ),
                    }}>
                    </TextField>
        </Container>
    )
}