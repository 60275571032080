import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 24px;
    padding:16px 24px;
    max-height: 90vh;
    height: auto;
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: #989898 #FFFFFF; 
        
    &::-webkit-scrollbar {
        width: 6px; 
    }
        
    &::-webkit-scrollbar-thumb {
        background-color: #989898; 
        border-radius: 4px; 
    }
        
    &::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }
`;

export const Header = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DCE4FF;
    position: relative;
    padding-bottom: 8px;
    
    h1 {
        font-weight: 600;
        font-size: 1rem !important;
        color: var(--txt-title);
        text-align: center;
    };

    img {
        height: 1.2rem;
        position: absolute;
        right: 0px;
        cursor: pointer;
        transition: transform .2s;
        
        &:hover {
            transform: scale(1.05);
        }
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 9px 12px;
    display: flex;
    flex-direction: column;
`;

export const Values = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--txt-subtitle);
    text-align: left; 
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    
    h1 {
        font-weight: 500;
        font-size: 0.8rem;
        margin-left: 0.2rem;
    }
`;

export const Row = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding-left: 16px;

    h5 {
        font-size: 0.7rem !important;
        font-weight: 300;
        width: 100%;
        color: #54577A; 
    }

    h4 {
        font-size: 0.8rem !important;
        font-weight: 400;
        text-align: left;
        width: 50%;
        
        b {
            font-weight: 500;
        }
    }

    h3 {
        font-size: 0.7rem !important;
        font-weight: 400;
        width: 100%;
    
        b{
            font-weight: 500;
        }
    }

    h1 {
        font-weight: 500;
        font-size: 0.8rem !important;
        color: var(--txt-title);
        text-align: left;
        line-height: 150%;
    };
`;

export const List = styled.div`
    height: auto;
    width: 100%;
    padding: 9px 24px;
    display: flex;
    flex-direction: column;

    li {
        font-weight: 600;
        font-size: 0.9rem !important;
        color: var(--txt-subtitle);
        text-align: left;
        padding:9px 0px;
    };
`;

export const Divider = styled.div`
    color: var(--txt-subtitle);
    width: 100%;
    height: 0px;
    border: 0.05px solid rgba(84, 87, 122, 0.4);
    margin-top: 8px;
    margin-bottom: 8px;
    opacity:0.3;
`;

export const ListTitle = styled.h1`
    font-weight: 600;
    font-size: 0.8rem !important;
    color: var(--txt-title);
    text-align: left;
`;

export const Print = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 794px;
    height: 1122.52px;
    margin-top: 24px;
    padding:2% 4%;
`;

export const PrintHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;

    h4 {
        font-weight: 600;
        font-size: 1rem !important;
        color: var(--txt-title);
        text-align: left;
    }

    h3 {
        font-size: 0.9rem !important;
        font-weight: 400;
        text-align: right;
    }
`;

export const Tabs = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    border-bottom: 0.2px solid rgba(84, 87, 122, 0.4);

    h4 {
        padding-top: 0.8rem;
        padding-bottom: 0.4rem;
        cursor: pointer;
        font-weight: 500;
        font-size: 0.8rem;
        color: var(--txt-subtitle);
        text-align: center;
    }
`;

export const ContainerTab = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
`;