import { useState, useContext } from "react";
import Button from "../../../../components/Button";
import Spinner from "../../../../components/Spinner";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../../assets/icons/index';
import AppContext from "../../../../state/App.context";
import { UseBeneficiationCompanies } from "../../../../hooks/useBeneficiationCompanies";

export default function ModalRemoveBeneficiationCompany({ open, setSelectedItem, width, height, name, id, updateRegistersList }) {
  const { deleteBeneficiationCompany } = UseBeneficiationCompanies();
  const [loading, setLoading] = useState(false);
  const title = 'Remover empresa de beneficiamento';
  const [, setSnack] = useContext(AppContext).snackState;

  function handleClose() {
    setSelectedItem({ open: false, mode: '', name: '', id: null });
  }

  async function handleRemoveBeneficiationCompany(event) {
    try {
      setLoading(true);
      event.preventDefault();
      const response = await deleteBeneficiationCompany(id);

      if (response.success) {
        handleClose();
        updateRegistersList(true);
        
        setSnack({
          open: true,
          severity: 'success',
          message: response?.message,
        })
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <h1>Deseja remover a empresa de beneficiamento <b>{name}</b>? <b>Essa ação não poderá ser desfeita.</b></h1>
          </Row>
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => setSelectedItem({ open: false, mode: '', name: '', id: null })}
            ></Button>
            <Button
              label="Remover a empresa de beneficiamento"
              background="#FF4D5E"
              color="white"
              borderColor="#FF4D5E"
              disabled={false}
              onClick={handleRemoveBeneficiationCompany}
            ></Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>{title}</h1>
          <img src={Close} onClick={() => setSelectedItem({ open: false, mode: '', name: '', id: null })} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}