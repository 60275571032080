import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const Container = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    transition: transform .2s;
    &:hover {
        transform: scale(1.05);
      }
    max-width: 385px;
    ` ;

export const Icon = styled.img`
    width: 4vw;
    margin: 0.8vw;
` ;
export const Info = styled.div `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 1.6vw 0.4vh;
    h1{
        font-weight: 500;
        font-size: 0.8rem !important;
        line-height: 150%;
        color: var(--txt-title);
        text-align: left;
        margin-bottom: 0.2rem;
    }
    h2{
        font-weight: 400;
        font-size: 0.7rem !important;
        line-height: 150%;
        color: var(--txt-subtitle);
        text-align: left;

    }
`;

