import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/Header";
import {
  Container,
  Content,
  Row,
  Title,
  RowContent,
  ValuesRow,
  SectionTitle,
  Column,
  RowWeeks,
  ButtonWeek,
  ContentLoading,
} from "./styles";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import moment from "moment";
import AppContext from "../../state/App.context";
import Autocomplete from "../../components/Autocomplete";
import Subsidiary from "../../components/Subsidiary";
import { UseSubsidiaries } from "../../hooks/useSubsidiaries";
import { UseProviders } from "../../hooks/useProviders";
import { UseCostCenters } from "../../hooks/useCostCenters";
import { UseCostCategories } from "../../hooks/useCostCategories";
import { UseOrders } from "../../hooks/useOrders";
import { UseAccounts } from "../../hooks/useAccounts";
import FinantialBalance from "../../components/FinantialBalance";
import ProgressBar from "../../components/Progressbar";
import ValueBox from "../../components/ValueBox";
import { formatMoney } from "../../services/functions";
import Datepicker from "../../components/Datepicker";
import ModalAddBalance from "./ModalAddBalance";
import ModalReport from "./ModalReport";
import ModalReportExcel from "./ModalReportExcel";
import {
  Balance,
  NextPageActive,
  PreviousPageActive,
  Simulator,
} from "../../assets/icons";
import { Tooltip } from "@material-ui/core";
import Spinner from "../../components/Spinner";
import SubsidiariesTotal from "../../components/SubsidiariesTotal";
import Switch from "../../components/Switch";
import ModalSimulationExcel from "./ModalSimulationExcel";
import ModalCategories from "./ModalCategories";
import { IconButton } from "@mui/material";
import { Pdf, Sheets } from "../../assets/icons";
import { UseFinishedProductOrders } from "../../hooks/useFinishedProductOrders";

let currentDate = moment();
let lastSunday = currentDate.clone().day("Sunday");
let nextSaturday = currentDate.clone().day("Saturday");

const defaultFilters = {
  provider: { id: 0, label: "Todos" },
  subsidiary: { id: 0, label: "Todas" },
  costCenter: { id: 0, label: "Todos" },
  costCategory: { id: 0, label: "Todas" },
  startDate: lastSunday.format("YYYY-MM-DD"),
  endDate: nextSaturday.format("YYYY-MM-DD"),
  pages: 1,
  limit: 100000,
};

const defaultSumsInfo = {
  total: {
    sum_pending_inputs: 0,
    sum_resolved_inputs: 0,
    sum_pending_outputs: 0,
    sum_resolved_outputs: 0,
    balance: 0,
    result: 0,
  },
  cash: {
    sum_pending_inputs: 0,
    sum_resolved_inputs: 0,
    sum_pending_outputs: 0,
    sum_resolved_outputs: 0,
    balance: 0,
    result: 0,
  },
  manager: {
    sum_pending_inputs: 0,
    sum_resolved_inputs: 0,
    sum_pending_outputs: 0,
    sum_resolved_outputs: 0,
    balance: 0,
    result: 0,
  },
};

function FinantialSchedule() {
  const { getFilteredOrders, getOrdersSalary } = UseOrders();
  const { getFinishedProductOrdersFilt } = UseFinishedProductOrders();
  const { getLastBalance } = UseAccounts();
  const { subsidiaries } = UseSubsidiaries();
  const { providers } = UseProviders();
  const { costCenters } = UseCostCenters();
  const { costCategories } = UseCostCategories();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [filters, setFilters] = useState(defaultFilters);
  const [openModalAddBalance, setOpenModalAddBalance] = useState(false);
  const [openModalCategories, setOpenModalCategories] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalReportExcel, setOpenModalReportExcel] = useState(false);
  const [openModalSimulationExcel, setOpenModalSimulationExcel] =
    useState(false);
  const [subsidiariesInfo, setSubsidiariesInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sumsInfo, setSumsInfo] = useState(defaultSumsInfo);
  const [filterByPaymentDate, setFilterByPaymentDate] = useState(false);
  const [filterByOriginalExpDate, setFilterByOriginalExpDate] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [allData, setAllData] = useState([]);
  const [, setDataIsLoaded] = useState(false);

  function transformDateFormat(dateString) {
    const originalDate = moment(dateString, "YYYY-MM-DD");
    const formattedDate = originalDate.format("DD/MM/YYYY");
    return formattedDate;
  }

  let titleToShow =
    filters.startDate !== "''" && filters.endDate !== "''"
      ? transformDateFormat(filters.startDate) +
      "-" +
      transformDateFormat(filters.endDate)
      : "";

  let subsidiariesOptions = [];
  subsidiaries &&
    !subsidiaries.error &&
    subsidiaries?.forEach((element) => {
      if (element.isactive) {
        subsidiariesOptions.push({
          id: element?.id_subsidiary,
          label: element?.subsidiary_name,
        });
      }
    });

  let providersOptions = [];
  providers &&
    !providers.error &&
    providers?.forEach((element) => {
      if (element.isactive) {
        providersOptions.push({
          id: element?.id_providers,
          label: element?.providers_name,
        });
      }
    });

  let costCentersOptions = [];
  costCenters &&
    !costCenters.error &&
    costCenters?.forEach((element) => {
      if (element.isactive) {
        costCentersOptions.push({
          id: element?.costs_center_name,
          label: element?.costs_center_name,
        });
      }
    });

  let costCategoriesOptions = [];
  costCategories &&
    !costCategories.error &&
    costCategories?.forEach((element) => {
      if (element.isactive) {
        costCategoriesOptions.push({
          id: element?.costs_categories_name,
          label: element?.costs_categories_name,
        });
      }
    });

  function findMaxPosition(array) {
    let maxPosition = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i] === "Pendente") {
        maxPosition = i;
      }
    }
    return maxPosition;
  }

  function isDateInRange(date, startRange, endRange) {
    var dateMoment = moment(date);
    var startRangeMoment = moment(startRange);
    var endRangeMoment = moment(endRange);

    return dateMoment.isBetween(startRangeMoment, endRangeMoment, null, "[]");
  }

  function filterByUniqueOrderId(array) {
    const seenIds = new Set();
    const result = [];

    array.forEach((object) => {
      const orderId = object.id_order;
      if (!seenIds.has(orderId)) {
        seenIds.add(orderId);
        result.push({ ...object, hasMultiplesEmployees: false });
      } else {
        const existingObject = result.find((obj) => obj.id_order === orderId);
        if (!existingObject.hasMultiplesEmployees) {
          existingObject.hasMultiplesEmployees = true;
        }
      }
    });

    return result;
  }

  function filterSalary(responseAll) {
    try {
      let filteredResponse = [];
      responseAll &&
        responseAll?.forEach((element) => {
          if (!element.issalary) {
            filteredResponse.push(element);
          }
        });
      return filteredResponse;
    } catch (err) {
      console.log(err);
    }
  }

  async function gettingLastBalances() {
    try {
      let endDate = moment(filters?.endDate, "YYYY-MM-DD");
      endDate = moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      const response = await getLastBalance(endDate);
      let lastBalances = [];
      if (response.success) {
        lastBalances = response?.data;
        return lastBalances;
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: response.message,
        });
        return [];
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function formatingSubsidiariesInfo(responseAll) {
    try {
      let responseOrders = filterByUniqueOrderId(responseAll);
      responseOrders = filters?.provider?.id
        ? filterSalary(responseOrders)
        : responseOrders;
      let lastBalances = await gettingLastBalances();
      let balance_total = 0;
      let balance_manager = 0;
      let balance_cash = 0;
      lastBalances.forEach((balance) => {
        balance_total += parseFloat(balance?.value);
        if (balance?.type === "Gerenciador") {
          balance_manager += parseFloat(balance?.value);
        } else {
          balance_cash += parseFloat(balance?.value);
        }
      });
      let total = {
        sum_pending_inputs: 0,
        sum_resolved_inputs: 0,
        sum_pending_outputs: 0,
        sum_resolved_outputs: 0,
        percent_inputs: 0,
        percent_outputs: 0,
        balance: balance_total,
        balances: lastBalances,
        result: 0,
      };
      let cash = {
        sum_pending_inputs: 0,
        sum_resolved_inputs: 0,
        sum_pending_outputs: 0,
        sum_resolved_outputs: 0,
        percent_inputs: 0,
        percent_outputs: 0,
        balance: balance_cash,
        result: 0,
      };
      let manager = {
        sum_pending_inputs: 0,
        sum_resolved_inputs: 0,
        sum_pending_outputs: 0,
        sum_resolved_outputs: 0,
        percent_inputs: 0,
        percent_outputs: 0,
        balance: balance_manager,
        result: 0,
      };
      let groupedOrders = [];
      if (responseOrders && responseOrders.length > 0) {
        groupedOrders = Object.values(
          responseOrders.reduce((result, order) => {
            const subsidiaryName = order.subsidiary_name;
            const full_name = order?.hasMultiplesEmployees
              ? "Remuneração Rateada"
              : order?.full_name;
            if (!result[subsidiaryName]) {
              result[subsidiaryName] = {
                subsidiary_name: subsidiaryName,
                pending_inputs_total: 0,
                pending_inputs: [],
                resolved_inputs: [],
                pending_outputs_total: 0,
                pending_outputs: [],
                resolved_outputs: [],
                resolved_inputs_total: 0,
                resolved_outputs_total: 0,
              };
            }
            const lastPendingInput = findMaxPosition(order?.status);
            const status = lastPendingInput === -1 ? "Executado" : "Pendente";
            const type = order.type;
            if (!order.wasstocked) {
              if (order?.amount_parcels === 0) {
                const valueToAdd =
                  status === "Pendente"
                    ? parseFloat(order.total_order_value) -
                    parseFloat(order.total_order_value_exec) -
                    parseFloat(order?.discount || 0)
                    : parseFloat(order.total_order_value_exec);
                if (status === "Pendente" && type === "Entrada") {
                  result[subsidiaryName].pending_inputs_total += valueToAdd;
                  result[subsidiaryName].pending_inputs.push(order);
                  total.sum_pending_inputs += valueToAdd;
                  if (order?.paymenttype_name === "Dinheiro") {
                    cash.sum_pending_inputs += valueToAdd;
                  } else {
                    manager.sum_pending_inputs += valueToAdd;
                  }
                } else if (status === "Pendente" && type === "Saída") {
                  result[subsidiaryName].pending_outputs_total += valueToAdd;
                  result[subsidiaryName].pending_outputs.push({
                    ...order,
                    full_name,
                  });
                  total.sum_pending_outputs += valueToAdd;
                  if (order?.paymenttype_name === "Dinheiro") {
                    cash.sum_pending_outputs += valueToAdd;
                  } else {
                    manager.sum_pending_outputs += valueToAdd;
                  }
                } else if (status === "Executado" && type === "Entrada") {
                  result[subsidiaryName].resolved_inputs_total += valueToAdd;
                  total.sum_resolved_inputs += valueToAdd;
                  if (order?.paymenttype_name === "Dinheiro") {
                    cash.sum_resolved_inputs += valueToAdd;
                  } else {
                    manager.sum_resolved_inputs += valueToAdd;
                  }
                  result[subsidiaryName].resolved_inputs.push(order);
                } else if (status === "Executado" && type === "Saída") {
                  result[subsidiaryName].resolved_outputs_total += valueToAdd;
                  total.sum_resolved_outputs += valueToAdd;
                  if (order?.paymenttype_name === "Dinheiro") {
                    cash.sum_resolved_outputs += valueToAdd;
                  } else {
                    manager.sum_resolved_outputs += valueToAdd;
                  }
                  result[subsidiaryName].resolved_outputs.push({
                    ...order,
                    full_name,
                  });
                }
              } else {
                for (var i = 0; i < order?.amount_parcels; i++) {
                  let parcelStatus = order?.status[i];
                  const parcelValueToAdd = order?.values_parcels[i];

                  const parcelExpirationDate = moment(order?.expiration_date[i])
                    .add(3, "hours")
                    .format("YYYY-MM-DD");

                  const parcelOriginalExpirationDate = moment(order?.original_expiration_date[i])
                    .add(3, "hours")
                    .format("YYYY-MM-DD");

                  const parcelPaymentDate = moment(order?.payment_date[i])
                    .add(3, "hours")
                    .format("YYYY-MM-DD");

                  const isBetween = isDateInRange(
                    parcelExpirationDate,
                    filters.startDate,
                    filters.endDate
                  );


                  const isBetweenPayment = isDateInRange(
                    parcelPaymentDate,
                    filters.startDate,
                    filters.endDate
                  );



                  const isBetweenOriginal = isDateInRange(
                    parcelOriginalExpirationDate,
                    filters.startDate,
                    filters.endDate
                  );

                  const parcelToAdd = {
                    ...order,
                    full_name,
                    current_parcel_name: i + 1 + "/" + order?.amount_parcels,
                    current_parcel_value: parcelValueToAdd,
                    current_parcel_expiration_date: order?.expiration_date[i],
                    original_parcel_expiration_date:
                      order?.original_expiration_date[i],
                    current_parcel_payment_date: order?.payment_date[i],
                  };
                  if (filterByOriginalExpDate) {
                    if (
                      parcelStatus === "Pendente" &&
                      type === "Entrada" &&
                      isBetweenOriginal
                    ) {
                      result[subsidiaryName].pending_inputs_total +=
                        parcelValueToAdd;
                      result[subsidiaryName].pending_inputs.push(parcelToAdd);
                      total.sum_pending_inputs += parcelValueToAdd;
                      manager.sum_pending_inputs += parcelValueToAdd;
                    } else if (
                      parcelStatus === "Pendente" &&
                      type === "Saída" &&
                      isBetweenOriginal
                    ) {
                      result[subsidiaryName].pending_outputs_total +=
                        parcelValueToAdd;
                      result[subsidiaryName].pending_outputs.push(parcelToAdd);
                      total.sum_pending_outputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_pending_outputs += parcelValueToAdd;
                      } else {
                        manager.sum_pending_outputs += parcelValueToAdd;
                      }
                    } else if (
                      parcelStatus === "Executado" &&
                      type === "Entrada" &&
                      isBetweenOriginal
                    ) {
                      result[subsidiaryName].resolved_inputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_inputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_inputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_inputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_inputs.push(parcelToAdd);
                    } else if (
                      parcelStatus === "Executado" &&
                      type === "Saída" &&
                      isBetweenOriginal
                    ) {
                      result[subsidiaryName].resolved_outputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_outputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_outputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_outputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_outputs.push(parcelToAdd);
                    }
                  } else if (filterByPaymentDate) {
                    if (
                      parcelStatus === "Executado" &&
                      type === "Entrada" &&
                      isBetweenPayment
                    ) {
                      result[subsidiaryName].resolved_inputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_inputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_inputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_inputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_inputs.push(parcelToAdd);
                    } else if (
                      parcelStatus === "Executado" &&
                      type === "Saída" &&
                      isBetweenPayment
                    ) {
                      result[subsidiaryName].resolved_outputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_outputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_outputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_outputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_outputs.push(parcelToAdd);
                    }

                  }
                  else {
                    if (
                      parcelStatus === "Pendente" &&
                      type === "Entrada" &&
                      isBetween
                    ) {
                      result[subsidiaryName].pending_inputs_total +=
                        parcelValueToAdd;
                      result[subsidiaryName].pending_inputs.push(parcelToAdd);
                      total.sum_pending_inputs += parcelValueToAdd;
                      manager.sum_pending_inputs += parcelValueToAdd;
                    } else if (
                      parcelStatus === "Pendente" &&
                      type === "Saída" &&
                      isBetween
                    ) {
                      result[subsidiaryName].pending_outputs_total +=
                        parcelValueToAdd;
                      result[subsidiaryName].pending_outputs.push(parcelToAdd);
                      total.sum_pending_outputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_pending_outputs += parcelValueToAdd;
                      } else {
                        manager.sum_pending_outputs += parcelValueToAdd;
                      }
                    } else if (
                      parcelStatus === "Executado" &&
                      type === "Entrada" &&
                      isBetween
                    ) {
                      result[subsidiaryName].resolved_inputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_inputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_inputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_inputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_inputs.push(parcelToAdd);
                    } else if (
                      parcelStatus === "Executado" &&
                      type === "Saída" &&
                      isBetween
                    ) {
                      result[subsidiaryName].resolved_outputs_total +=
                        parcelValueToAdd;
                      total.sum_resolved_outputs += parcelValueToAdd;
                      if (order?.paymenttype_name === "Dinheiro") {
                        cash.sum_resolved_outputs += parcelValueToAdd;
                      } else {
                        manager.sum_resolved_outputs += parcelValueToAdd;
                      }
                      result[subsidiaryName].resolved_outputs.push(parcelToAdd);
                    }
                  }
                }
              }
            }
            return result;
          }, {})
        );
      }
      total.percent_inputs = total.sum_resolved_inputs
        ? Math.floor(
          (total.sum_resolved_inputs /
            (total.sum_resolved_inputs + total.sum_pending_inputs)) *
          100
        )
        : 0;
      total.percent_outputs = total.sum_resolved_outputs
        ? Math.floor(
          (total.sum_resolved_outputs /
            (total.sum_resolved_outputs + total.sum_pending_outputs)) *
          100
        )
        : 0;
      total.result =
        total.sum_resolved_inputs +
        total.sum_pending_inputs +
        total.balance -
        (total.sum_resolved_outputs + total.sum_pending_outputs);

      cash.percent_inputs = cash.sum_resolved_inputs
        ? Math.floor(
          (cash.sum_resolved_inputs /
            (cash.sum_resolved_inputs + cash.sum_pending_inputs)) *
          100
        )
        : 0;
      cash.percent_outputs = cash.sum_resolved_outputs
        ? Math.floor(
          (cash.sum_resolved_outputs /
            (cash.sum_resolved_outputs + cash.sum_pending_outputs)) *
          100
        )
        : 0;
      cash.result =
        cash.sum_resolved_inputs +
        cash.sum_pending_inputs +
        cash.balance -
        (cash.sum_resolved_outputs + cash.sum_pending_outputs);

      manager.percent_inputs = manager.sum_resolved_inputs
        ? Math.floor(
          (manager.sum_resolved_inputs /
            (manager.sum_resolved_inputs + manager.sum_pending_inputs)) *
          100
        )
        : 0;
      manager.percent_outputs = manager.sum_resolved_outputs
        ? Math.floor(
          (manager.sum_resolved_outputs /
            (manager.sum_resolved_outputs + manager.sum_pending_outputs)) *
          100
        )
        : 0;
      manager.result =
        manager.sum_resolved_inputs +
        manager.sum_pending_inputs +
        manager.balance -
        (manager.sum_resolved_outputs + manager.sum_pending_outputs);

      setSumsInfo({
        total,
        manager,
        cash,
      });
      setSubsidiariesInfo(groupedOrders);
    } catch (err) {
      console.log(err);
    }
  }

  async function getInfo() {
    try {
      setLoading(true);
      setSubsidiariesInfo([]);
      setSumsInfo(defaultSumsInfo);
      let formattedFilters = {
        type: "''",
        costCenter: filters?.costCenter?.id ? filters?.costCenter?.label : "''",
        costCategory: filters?.costCategory?.id
          ? filters?.costCategory?.label
          : "''",
        subsidiary: filters?.subsidiary?.id ? filters?.subsidiary?.label : "''",
        provider: filters?.provider?.id ? filters?.provider?.label : "''",
        trip: "''",
        paymentType: filters?.paymentType?.id
          ? filters?.paymentType?.label
          : "''",
        boat: "''",
        status: "''",
        startDate: filters.startDate,
        endDate: filters.endDate,
        harvest: "''",
        employee: "''",
        pages: filters?.pages,
        limit: filters?.limit,
        filterByPaymentDate,
        filterByOriginalExpDate,
      };
      let formattedFiltersSalary = { ...formattedFilters, full_name: "''" };
      let ordersResponse = await getFilteredOrders(formattedFilters);
      let ordersSalaryResponse = await getOrdersSalary(formattedFiltersSalary);
      let finishedProductOrders = await getFinishedProductOrdersFilt(formattedFilters);

      if (ordersResponse.success && ordersSalaryResponse.success && finishedProductOrders.success) {
        let ordersCopy = ordersResponse?.data?.data?.map((order_item) => {
          return {
            ...order_item,
            isFinishedProductOrder: false,
          }
        });

        let ordersSalaryCopy = ordersSalaryResponse?.data?.data?.map((order_salary_item) => {
          return {
            ...order_salary_item,
            isFinishedProductOrder: false,
          }
        });
        
        let finishedProductOrdersCopy = finishedProductOrders?.data?.map((finishedProductOrder) => {
          return {
            ...finishedProductOrder,
            isFinishedProductOrder: true,
          }
        });

        let allOrders = [...ordersCopy, ...ordersSalaryCopy, ...finishedProductOrdersCopy];
        setAllData(allOrders);
        formatingSubsidiariesInfo(allOrders);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: ordersResponse.message,
        });
      }
      setDataIsLoaded((prevValue) => !prevValue);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filterByPaymentDate, filterByOriginalExpDate, refreshData]);

  function verifyFilterProps(obj) {
    let count = 0;
    for (const prop in obj) {
      if (
        obj.hasOwnProperty(prop) &&
        prop !== "limit" &&
        prop !== "pages" &&
        obj[prop].id !== 0
      ) {
        count++;
      }
    }
    return count;
  }

  let activeFilters = verifyFilterProps(filters);
  let filterButtonLabel = activeFilters
    ? "Filtros(" + activeFilters + ")"
    : "Filtros";

  function cleanFilters() {
    setFilters({ ...defaultFilters });
  }

  function goToNextWeek() {
    setFilters({
      ...filters,
      startDate: moment(filters?.startDate, "YYYY-MM-DD")
        .add(1, "weeks")
        .format("YYYY-MM-DD"),
      endDate: moment(filters?.endDate, "YYYY-MM-DD")
        .add(1, "weeks")
        .format("YYYY-MM-DD"),
    });
  }
  function goToPreviousWeek() {
    setFilters({
      ...filters,
      startDate: moment(filters?.startDate, "YYYY-MM-DD")
        .subtract(1, "weeks")
        .format("YYYY-MM-DD"),
      endDate: moment(filters?.endDate, "YYYY-MM-DD")
        .subtract(1, "weeks")
        .format("YYYY-MM-DD"),
    });
  }

  function Filters() {
    return (
      <>
        <div
          style={{
            marginTop: 16,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            label="Limpar filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => {
              cleanFilters();
            }}
          ></Button>
        </div>
        <Datepicker
          label={filterByPaymentDate ? "Pagamento entre" : "Vencimento entre"}
          width={200}
          disabled={false}
          value={filters?.startDate}
          fontSize={"0.7rem"}
          onChange={(element) => {
            let filtersCopy = { ...filters };
            filtersCopy.startDate = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
          }}
        />
        <Datepicker
          label="e"
          width={200}
          disabled={false}
          value={filters?.endDate}
          fontSize={"0.7rem"}
          onChange={(element) => {
            let filtersCopy = { ...filters };
            filtersCopy.endDate = element;
            filtersCopy.pages = 1;
            setFilters(filtersCopy);
          }}
        />
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.subsidiary}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todas" }, ...subsidiariesOptions]}
            fontSize={"0.7rem"}
            label="Subsidiária"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.subsidiary = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.provider}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todos" }, ...providersOptions]}
            fontSize={"0.7rem"}
            label="Fornecedor/Comprador"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.provider = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.costCenter}
            width={200}
            disabled={false}
            options={[{ id: "''", label: "Todos" }, ...costCentersOptions]}
            fontSize={"0.7rem"}
            label="Centro de Custo"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.costCenter = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.costCategory}
            width={200}
            disabled={false}
            options={[{ id: "''", label: "Todos" }, ...costCategoriesOptions]}
            fontSize={"0.7rem"}
            label="Categoria de Custo"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.costCategory = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <br></br>
      </>
    );
  }

  if (loading && subsidiariesInfo.length > 0) {
    return (
      <Container>
        <Header
          title="Programação Financeira"
          subtitle="Visualize e gerencie todos pagamentos e recebimentos pendentes"
        />
        <Content>
          <Drawer
            open={openDrawer}
            handleOpen={setOpenDrawer}
            Children={Filters}
          ></Drawer>
          <Row style={{ marginBottom: 8 }}>
            <RowWeeks>
              <Tooltip title="Navegar para semana anterior">
                <ButtonWeek
                  src={PreviousPageActive}
                  alt="Semana anterior"
                  onClick={() => goToPreviousWeek()}
                ></ButtonWeek>
              </Tooltip>
              <Title>{titleToShow}</Title>
              <Tooltip title="Navegar para semana seguinte">
                <ButtonWeek
                  src={NextPageActive}
                  alt="Semana seguinte"
                  onClick={() => goToNextWeek()}
                ></ButtonWeek>
              </Tooltip>
            </RowWeeks>
            <Switch
              label="Filtrar por data de Vencimento Original"
              disabled={false}
              value={filterByOriginalExpDate}
              fontSize={14}
              onChange={setFilterByOriginalExpDate}
              multiline={false}
              color="black"
            ></Switch>
            <Switch
              label="Filtrar por data de pagamento"
              disabled={false}
              value={filterByPaymentDate}
              fontSize={14}
              onChange={setFilterByPaymentDate}
              multiline={false}
              color="black"
            ></Switch>
            <Tooltip title="Exportar relatório pdf">
              <IconButton onClick={() => setOpenModalReport(true)} color="info">
                <img src={Pdf} alt="PDF Icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Exportar relatório excel">
              <IconButton
                onClick={() => setOpenModalReportExcel(true)}
                color="info"
              >
                <img src={Sheets} alt="Sheets Icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Acessar simulador">
              <IconButton
                onClick={() => {
                  setOpenModalSimulationExcel(true);
                }}
                color="info"
              >
                <img src={Simulator} alt="Simulator Icons" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Atualizar saldo">
              <IconButton
                onClick={() => setOpenModalAddBalance(true)}
                color="info"
              >
                <img src={Balance} alt="Balance Icon" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Resumo das categorias">
              <IconButton
                onClick={() => setOpenModalCategories(true)}
                color="info"
              >
                <img src={Categories} alt="Categories Icon" />
              </IconButton>
            </Tooltip> */}
            <Button
              label={filterButtonLabel}
              background="transparent"
              color="#54577A"
              borderColor="#54577A"
              disabled={false}
              onClick={() => setOpenDrawer(true)}
            ></Button>
          </Row>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <Spinner width={40} fontSize={14}></Spinner>
          </div>
        </Content>
      </Container>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <ContentLoading>
          <Spinner width={24} fontSize={16}></Spinner>
        </ContentLoading>
      );
    } else {
      return (
        <Content>
          <Drawer
            open={openDrawer}
            handleOpen={setOpenDrawer}
            Children={Filters}
          ></Drawer>
          <Row style={{ marginBottom: 8 }}>
            <RowWeeks>
              <Tooltip title="Navegar para semana anterior">
                <ButtonWeek
                  src={PreviousPageActive}
                  alt="Semana anterior"
                  onClick={() => goToPreviousWeek()}
                ></ButtonWeek>
              </Tooltip>
              <Title>{titleToShow}</Title>
              <Tooltip title="Navegar para semana seguinte">
                <ButtonWeek
                  src={NextPageActive}
                  alt="Semana seguinte"
                  onClick={() => goToNextWeek()}
                ></ButtonWeek>
              </Tooltip>
            </RowWeeks>
            <Switch
              label="Filtrar por data de vencimento original"
              disabled={false}
              value={filterByOriginalExpDate}
              fontSize={12}
              onChange={setFilterByOriginalExpDate}
              multiline={false}
              color="black"
            ></Switch>
            <Switch
              label="Filtrar por data de pagamento"
              disabled={false}
              value={filterByPaymentDate}
              fontSize={12}
              onChange={setFilterByPaymentDate}
              multiline={false}
              color="black"
            ></Switch>
            <Tooltip title="Exportar relatório pdf">
              <IconButton onClick={() => setOpenModalReport(true)} color="info">
                <img src={Pdf} alt="PDF Icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Exportar relatório excel">
              <IconButton
                onClick={() => setOpenModalReportExcel(true)}
                color="info"
              >
                <img src={Sheets} alt="Sheets Icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Acessar simulador">
              <IconButton
                onClick={() => {
                  setOpenModalSimulationExcel(true);
                }}
                color="info"
              >
                <img src={Simulator} alt="Simulator Icons" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Atualizar saldo">
              <IconButton
                onClick={() => setOpenModalAddBalance(true)}
                color="info"
              >
                <img src={Balance} alt="Balance Icon" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Resumo das categorias">
              <IconButton
                onClick={() => setOpenModalCategories(true)}
                color="info"
              >
                <img src={Categories} alt="Categories Icon" />
              </IconButton>
            </Tooltip> */}
            <Button
              label={filterButtonLabel}
              background="transparent"
              color="#54577A"
              borderColor="#54577A"
              disabled={false}
              onClick={() => setOpenDrawer(true)}
            ></Button>
          </Row>
          <RowContent style={{ gridTemplateColumns: "35% 35% 29%" }}>
            <Column>
              <SectionTitle>Espécie</SectionTitle>
              <ValueBox
                loading={false}
                value={formatMoney(sumsInfo?.cash?.balance)}
                title="Saldo inicial"
                color={sumsInfo?.cash?.balance > 0 ? "#03A03E" : "#FF4D5E"}
              ></ValueBox>
              <ValuesRow>
                <ValueBox
                  loading={false}
                  value={formatMoney(
                    sumsInfo?.cash?.sum_resolved_outputs +
                    sumsInfo?.cash?.sum_pending_outputs
                  )}
                  title="Total"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.cash?.sum_resolved_outputs)}
                  title="Pago"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.cash?.sum_pending_outputs)}
                  title="Pendente"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
              </ValuesRow>
              <ProgressBar
                title="Progresso de pagamentos"
                percent={sumsInfo?.cash?.percent_outputs + "%"}
                barColor="var(--red)"
              ></ProgressBar>
              <ValuesRow>
                <ValueBox
                  loading={false}
                  value={formatMoney(
                    sumsInfo?.cash?.sum_resolved_inputs +
                    sumsInfo?.cash?.sum_pending_inputs
                  )}
                  title="Total"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.cash?.sum_resolved_inputs)}
                  title="Recebido"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.cash?.sum_pending_inputs)}
                  title="Pendente"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
              </ValuesRow>
              <ProgressBar
                title="Progresso de recebimentos"
                percent={sumsInfo?.cash?.percent_inputs + "%"}
                barColor="var(--green)"
              ></ProgressBar>
            </Column>
            <Column>
              <SectionTitle>Gerenciador</SectionTitle>
              <ValueBox
                loading={false}
                value={formatMoney(sumsInfo?.manager?.balance)}
                title="Saldo inicial"
                color={sumsInfo?.manager?.balance > 0 ? "#03A03E" : "#FF4D5E"}
              ></ValueBox>
              <ValuesRow>
                <ValueBox
                  loading={false}
                  value={formatMoney(
                    sumsInfo?.manager?.sum_resolved_outputs +
                    sumsInfo?.manager?.sum_pending_outputs
                  )}
                  title="Total"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.manager?.sum_resolved_outputs)}
                  title="Pago"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.manager?.sum_pending_outputs)}
                  title="Pendente"
                  color="#FF4D5E"
                  small={true}
                ></ValueBox>
              </ValuesRow>
              <ProgressBar
                title="Progresso de pagamentos"
                percent={sumsInfo?.manager?.percent_outputs + "%"}
                barColor="var(--red)"
              ></ProgressBar>
              <ValuesRow>
                <ValueBox
                  loading={false}
                  value={formatMoney(
                    sumsInfo?.manager?.sum_resolved_inputs +
                    sumsInfo?.manager?.sum_pending_inputs
                  )}
                  title="Total"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.manager?.sum_resolved_inputs)}
                  title="Recebido"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
                <ValueBox
                  loading={false}
                  value={formatMoney(sumsInfo?.manager?.sum_pending_inputs)}
                  title="Pendente"
                  color="#03A03E"
                  small={true}
                ></ValueBox>
              </ValuesRow>
              <ProgressBar
                title="Progresso de recebimentos"
                percent={sumsInfo?.manager?.percent_inputs + "%"}
                barColor="var(--green)"
              ></ProgressBar>
            </Column>
            <Column>
              <SectionTitle>Balanço Financeiro</SectionTitle>
              <FinantialBalance
                total={sumsInfo?.total}
                manager={sumsInfo?.manager}
                cash={sumsInfo?.cash}
              ></FinantialBalance>
            </Column>
          </RowContent>
          <SubsidiariesTotal
            resolved_outputs_total={sumsInfo?.total?.sum_resolved_outputs}
            pending_outputs_total={sumsInfo?.total?.sum_pending_outputs}
            resolved_inputs_total={sumsInfo?.total?.sum_resolved_inputs}
            pending_inputs_total={sumsInfo?.total?.sum_pending_inputs}
          ></SubsidiariesTotal>
          {subsidiariesInfo &&
            subsidiariesInfo.map((subsidiary, index) => {
              return (
                <Subsidiary
                  subsidiary={subsidiary}
                  key={index}
                  setRefreshData={setRefreshData}
                ></Subsidiary>
              );
            })}
        </Content>
      );
    }
  }

  return (
    <Container>
      <Header
        title="Programação Financeira"
        subtitle="Visualize e gerencie todos pagamentos e recebimentos pendentes"
      />
      {renderContent()}
      <ModalAddBalance
        open={openModalAddBalance}
        handleOpen={setOpenModalAddBalance}
        setRefreshData={setRefreshData}
        width={700}
        height={330}
      ></ModalAddBalance>
      <ModalReport
        open={openModalReport}
        handleOpen={setOpenModalReport}
        subsidiaries={subsidiariesInfo}
        startDate={filters.startDate}
        endDate={filters.endDate}
        sumsInfo={sumsInfo}
      ></ModalReport>
      <ModalReportExcel
        open={openModalReportExcel}
        handleOpen={setOpenModalReportExcel}
        subsidiaries={subsidiariesInfo}
        startDate={filters.startDate}
        endDate={filters.endDate}
        sumsInfo={sumsInfo}
      ></ModalReportExcel>
      <ModalSimulationExcel
        open={openModalSimulationExcel}
        handleOpen={setOpenModalSimulationExcel}
        data={subsidiariesInfo}
        startDate={filters.startDate}
        endDate={filters.endDate}
      ></ModalSimulationExcel>
      <ModalCategories
        open={openModalCategories}
        handleOpen={setOpenModalCategories}
        data={allData}
      ></ModalCategories>
    </Container>
  );
}

export default React.createElement(FinantialSchedule);
