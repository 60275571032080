import { Container, Header, Row, Content, Divider } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import { formatMoney } from "../../../services/functions";
import Table from "../../../components/Table";

export default function ModalVisualizeBeneficiationItem({ open, width, height, selectedItem, setSelectedItem, setShowModalVisualizeBeneficiationItem, columnsParcels, columnsItems }) {
  const beneficiationItem = selectedItem.item;

  function handleClose() {
    open = false;
    setSelectedItem(null);
    setShowModalVisualizeBeneficiationItem(false);
  }

  function renderContent() {
    return (
      <Content>
        <Row>
          <h1 style={{ width: '100%' }}><b>Id do item de beneficiamento:</b> {beneficiationItem.id}</h1>
        </Row>
        <Divider />
        <Row>
          <h4><b>Empresa de beneficiamento:</b> {beneficiationItem.beneficiation_company_name}</h4>
          <h4><b>Lote:</b> {beneficiationItem.batch}</h4>
          <h4><b>Valor total:</b> {formatMoney(beneficiationItem.total_value)}</h4>
        </Row>
        <Row>
          <h4><b>Tipo de pagamento:</b> {beneficiationItem.payment_type_name}</h4>
          <h4><b>Desconto:</b> {formatMoney(beneficiationItem.discount)}</h4>
          <h4> </h4>
        </Row>
        <Row><h4><b>Observações:</b> {beneficiationItem.notes}</h4></Row>
        <Row></Row>
        <Row><h4><b>Lista de Itens:</b></h4></Row>
        <Table
          columns={columnsItems}
          rows={beneficiationItem.items || []}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
        <Row><h4><b>Pagamento:</b></h4></Row>
        <Table
          columns={columnsParcels}
          rows={beneficiationItem.parcels || []}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
      </Content>
    )
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Detalhes do Item de Beneficiamento de Produto Acabado</h1>
          <img src={Close} onClick={handleClose} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}