import React, { useState, useContext, useEffect } from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
  Container, Content, Row
} from './styles';
import { UseBeneficiationCompanies } from "../../../hooks/useBeneficiationCompanies"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddBenefiationCompany from './ModalAddBeneficiationCompany/index';
import ModalEditBenefiationCompany from './ModalEditBeneficiationCompany/index';
import ModalRemoveBenefiationCompany from './ModalRemoveBeneficiationCompany/index';
import AppContext from "../../../state/App.context";

const columns = [
  {
    name: 'Nome',
    key: 'name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Descrição',
    key: 'description',
    type: 'string',
    unit: '',
  },
]

function BeneficiationCompanies() {
  const { getBeneficiationCompanies } = UseBeneficiationCompanies();
  const [search, setSearch] = useState('');
  const [openModalAddBeneficiarionCompany, setOpenModalAddBeneficiarionCompany] = useState(false);
  const [selectedBeneficiartonCompany, setSelectedBeneficiationCompany] = useState({ open: false, mode: '', name: '', id: null });
  const [, setSnack] = useContext(AppContext).snackState;
  const [beneficiationCompanies, setBeneficiationCompanies] = useState([]);
  const [beneficiationCompaniesToShow, setBeneficiationCompaniesToShow] = useState([]);
  const [fetchRegistersFromServer, setFetchRegistersFromServer] = useState(false);

  useEffect(() => {
    async function getBeneficiationCompanyList() {
      setBeneficiationCompanies([]);
      setFetchRegistersFromServer(false);
      let resultBeneficiationCompanyList = await getBeneficiationCompanies();

      if (resultBeneficiationCompanyList.success) {

        if (resultBeneficiationCompanyList.data) {
          setBeneficiationCompanies(resultBeneficiationCompanyList.data);
        }
        
        if (!resultBeneficiationCompanyList.data) {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Nenhum dado retornado do servidor.',
          })
        }
      } 

      if (!resultBeneficiationCompanyList.success) {
        setSnack({
          open: true,
          severity: 'error',
          message: resultBeneficiationCompanyList.message,
        }) 
      }
    }
    
    getBeneficiationCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRegistersFromServer])

  useEffect(() => {
    if (search.length > 0) {
      setBeneficiationCompaniesToShow(beneficiationCompanies.filter(a => a.name.toLowerCase().includes(search.toLowerCase())));
    } else {
      setBeneficiationCompaniesToShow(beneficiationCompanies);
    }
  }, [search, beneficiationCompanies]);

  return (
    <Container>
      <Header title="Empresas de Beneficiamento" subtitle="Gerencie as empresas de beneficiamento cadastradas" />
      <Content>
        <Row>
          <Button
            label="Adicionar empresa de beneficiamento"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => setOpenModalAddBeneficiarionCompany(true)}
          ></Button>
          <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar empresa de beneficiamento..." fontSize={'0.7rem'} ></Searchbar>
        </Row>
        <Table
          columns={columns}
          rows={beneficiationCompaniesToShow || []}
          hasEditing={true}
          hasRemoving={true}
          setSelectedItem={setSelectedBeneficiationCompany}
        ></Table>
      </Content>
      <ModalAddBenefiationCompany
        open={openModalAddBeneficiarionCompany}
        handleOpen={setOpenModalAddBeneficiarionCompany}
        width={700}
        height={430}
        updateRegistersList={setFetchRegistersFromServer}
      ></ModalAddBenefiationCompany>
      <ModalEditBenefiationCompany
        open={selectedBeneficiartonCompany.open && selectedBeneficiartonCompany.mode === 'edit'}
        setSelectedItem={setSelectedBeneficiationCompany}
        selectedItem={selectedBeneficiartonCompany}
        width={700}
        height={330}
        updateRegistersList={setFetchRegistersFromServer}
      ></ModalEditBenefiationCompany>
      <ModalRemoveBenefiationCompany
        open={selectedBeneficiartonCompany.open && selectedBeneficiartonCompany.mode === 'remove'}
        setSelectedItem={setSelectedBeneficiationCompany}
        name={selectedBeneficiartonCompany?.name}
        id={selectedBeneficiartonCompany?.id}
        width={600}
        height={170}
        updateRegistersList={setFetchRegistersFromServer}
      ></ModalRemoveBenefiationCompany>
    </Container>
  );
};

export default React.createElement(BeneficiationCompanies);