import { useState, useContext } from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import AppContext from "../../../state/App.context";
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";
import { UseOrders } from "../../../hooks/useOrders";

export default function ModalRemoveFinishedProductOrder({ open, width, height, selectedItem, setSelectedItem, setUpdateFinishedProductOrdersList }) {
  const { deleteFinishedProductOrder } = UseFinishedProductOrders();
  const { updateOnDelete } = UseOrders();
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  function handleClose() {
    setSelectedItem({
      open: false,
      mode: 'view',
    });
  }

  async function handleFinishedProductOrder(event) {
    try {
      setLoading(true);
      event.preventDefault();
      let responseDeleteOrder = await updateOnDelete(selectedItem.item.id_order);
      
      if (responseDeleteOrder.success) {
        let responseDeleteFinishedProductOrder = await deleteFinishedProductOrder(selectedItem.item.id_finished_product_order);
      
        if (responseDeleteFinishedProductOrder.success) {
          handleClose();
          setUpdateFinishedProductOrdersList(true);

          setSnack({
            open: true,
            severity: 'success',
            message: responseDeleteFinishedProductOrder?.message,
          })
        } else {
          setSnack({
            open: true,
            severity: 'error',
            message: 'Não foi possível remover o pedido de produto acabado.',
          })
        }
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível remover o pedido padrão associado ao pedido de produto acabado.',
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <h1>Deseja remover o pedido de produto acabado selecionado <b>{selectedItem.item.id_finished_product_order}</b>? <b>Essa ação não poderá ser desfeita.</b></h1>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={handleClose}
            ></Button>
            <Button
              label="Remover o pedido"
              background="#FF4D5E"
              color="white"
              borderColor="#FF4D5E"
              disabled={false}
              onClick={handleFinishedProductOrder}
            ></Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Remover Pedido de Produto Acabado</h1>
          <img src={Close} onClick={handleClose} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}