export const defaultFilters = {
  costCenter: "''",
  subsidiary: "''",
  trip: "''",
  harvest: "''",
  boat: "''",
  startDate: "''",
  endDate: "''",
  pages: 1, // implement
  limit: 10,
};

export const columns = [
  {
    name: '#Pedido',
    key: 'id_finished_product_order',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: '#Pedido de Entrada',
    key: 'id_order',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Criado em',
    key: 'created_at',
    type: 'date',
    unit: '',
    show: true,
  },
  {
    name: 'Viagem',
    key: 'trip_name',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Safra',
    key: 'harvest',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Barco',
    key: 'boat_name',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Subsidiária',
    key: 'subsidiary_name',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Centro de Custo',
    key: 'cost_center_name',
    type: 'string',
    unit: '',
    show: true,
  },
  {
    name: 'Criado por',
    key: 'created_by',
    type: 'string',
    unit: '',
    show: true,
  },
]

export const harvestOptions = [
  {
    id: 2023,
    label: '2023',
  },
  {
    id: 2024,
    label: '2024',
  },
  {
    id: 2025,
    label: '2025',
  },
];
