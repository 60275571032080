import React from "react";
import Header from '../../components/Header';
import BoxButton
  from '../../components/BoxButton';
import {
  Container, Content
} from './styles';
import { FinishedProductOrderStockSelectedIcon, StockSelected } from '../../assets/icons/index';
import LocalStorageService from '../../services/storage';

const buttons = [
  {
    icon: StockSelected,
    title: "Estoque",
    explanation: "Gerencie o estoque",
    path: "/stock-options/stock",
    isAdmin: false,
  },
  {
    icon: FinishedProductOrderStockSelectedIcon,
    title: "Estoque Produto Acabado",
    explanation: "Gerencie o estoque das compras para produtos acabados",
    path: "/stock-options/finished-product-order-stock",
    isAdmin: false,
  },
]

function StockOptions() {
  const localStorageService = LocalStorageService();
  const userInfo = localStorageService.getUserInfo();
  const isAdmin = userInfo?.user_role === 'adm';
  return (
    <Container>
      <Header title="Estoques" subtitle="Gerencie os diversos tipos de estoque" />
      <Content>
        {buttons.map((button, index) => (
          <BoxButton key={index} icon={button.icon} title={button.title} explanation={button.explanation} path={button.path} show={(button.isAdmin && isAdmin) || !button.isAdmin}></BoxButton>
        ))}
      </Content>
    </Container>
  );
};

export default React.createElement(StockOptions);