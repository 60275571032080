import React, { useState, useContext, useEffect } from "react";
import Header from "../../../components/Header";
import { Container, Content, Row, RowPagination, Tabs } from "./styles";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import PaginationComponent from "../../../components/Pagination";
import AppContext from "../../../state/App.context";
import Autocomplete from "../../../components/Autocomplete";
import Table from "../../../components/Table";
import Datepicker from "../../../components/Datepicker";
import { UseSubsidiaries } from "../../../hooks/useSubsidiaries";
import { UseProviders } from "../../../hooks/useProviders";
import { UseProducts } from "../../../hooks/useProducts";
import ModalAddPurchase from "./ModalAddPurchase";
import TableNestedItemsAndParcels from "../../../components/TableNestedItemsAndParcels";
import ModalRemovePurchase from "./ModalRemovePurchase";
import ModalVisualizePurchase from "./ModalVisualizePurchase";
import ModalEditPurchase from "./ModalEditPurchase";
import moment from "moment";
import { UsePaymentTypes } from "../../../hooks/usePaymentTypes";
import { UseCostCategories } from "../../../hooks/useCostCategories";
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";

const columnsActualStock = [
  {
    name: "Item",
    key: "product_name",
    type: "string",
    unit: "",
  },
  {
    name: "Quantidade Disponivel",
    key: "remaining_amount",
    type: "number",
    unit: "",
  },
  {
    name: "Categoria de Custo",
    key: "cost_category_name",
    type: "string",
    unit: "",
  },
  {
    name: "Fornecedor",
    key: "supplier_name",
    type: "string",
    unit: "",
  },
  {
    name: "Subsidiária",
    key: "subsidiary_name",
    type: "string",
    unit: "",
  },
  {
    name: "Data de Compra",
    key: "purchase_date",
    type: "string",
    unit: "",
  },
];

const columnsOutputs = [
  {
    name: "Item",
    key: "product_name",
    type: "string",
    unit: "",
  },
  {
    name: "Quantidade Retirada",
    key: "allocated_amount",
    type: "float",
    unit: "",
  },
  {
    name: "Total",
    key: "total_withdrawal_value",
    type: "money",
    unit: "",
  },
  {
    name: "% do Total do Pedido",
    key: "percent_total_order_value",
    type: "percent",
    unit: "%",
  },
  {
    name: "Movimentado por",
    key: "allocated_by_email",
    type: "string",
    unit: "",
  },
  {
    name: "Movimentado em",
    key: "allocated_at",
    type: "date",
    unit: "",
  },
  {
    name: "Receptor",
    key: "recipient_name",
    type: "string",
    unit: "",
  }
];

const columnsLogs = [
  {
    name: "Ação",
    key: "user_action",
    type: "string",
    unit: "",
  },
  {
    name: "Usuário",
    key: "e_mail",
    type: "string",
    unit: "",
  },
  {
    name: "Data de criação",
    key: "created_at",
    type: "date",
    unit: "",
  },
];

const columnsPurchasesStock = [
  {
    name: 'Data de Compra',
    key: 'purchase_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Subsidiária',
    key: 'subsidiary_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Fornecedor',
    key: 'supplier_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Tipo de Pagamento',
    key: 'payment_type_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Desconto',
    key: 'discount',
    type: 'money',
    unit: '',
  },
]

const columnsParcels = [
  {
    name: 'Nº',
    key: 'number',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },

]

const columnsItems = [
  {
    name: 'Item',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]

const defaultFilters = {
  startDate: '',
  endDate: '',
  provider: { id: 0, label: "Todos" },
  subsidiary: { id: 0, label: "Todas" },
  page: 1,
  limit: 10,
  is_apportioned: true,
};

const defaultFiltersOutputs = {
  startDate: '',
  endDate: '',
  product: { id: 0, label: "Todos" },
  page: 1,
  limit: 10,
};

const defaultFiltersCurrentStock = {
  provider: { id: 0, label: "Todos" },
  subsidiary: { id: 0, label: "Todas" },
  page: 1,
  limit: 10,
};

const tabsStates = [
  {
    id: "current_stock",
    label: "Estoque Atual",
  },
  {
    id: "purchases_stock",
    label: "Compras para o Estoque",
  },
  {
    id: "outputs_stock",
    label: "Movimentações no Estoque",
  },
  {
    id: "logs_stock",
    label: "Histórico",
  },
];

function FinishedProductOrderStock() {
  const { getFinishedProductOrderPurchases, getFinishedProductOrderStockItems, getFinishedProductOrderStockOutputs, getFinishedProductOrderStockHistory } = UseFinishedProductOrders();
  const { subsidiaries } = UseSubsidiaries();
  const { products } = UseProducts();
  const { providers } = UseProviders();
  const { paymentTypes } = UsePaymentTypes();
  const { costCategories } = UseCostCategories();
  const [stockItemsToShow, setStockItemsToShow] = useState([]);
  const [stockItemsToShowPaginated, setStockItemsToShowPaginated] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [filtersOutputs, setFiltersOutputs] = useState({ ...defaultFiltersOutputs });
  const [filtersCurrentStock, setFiltersCurrentStock] = useState({ ...defaultFiltersCurrentStock });

  const [totalPagesOutputs, setTotalPagesOutputs] = useState(1);
  const [totalPagesForPurchasesStock, setTotalPagesForPurchasesStock] = useState(1);
  const [totalPagesForStockItens, setTotalPagesForStockItens] = useState(1);
  const [totalPagesStockHistory, setTotalPagesStockHistory] = useState(1);

  const [currentPageForStockHistory, setCurrentPageForStockHistory] = useState(1);

  const [, setSelectedItem] = useState({});
  const [selectedPurchaseItem, setSelectedPurchaseItem] = useState(null);

  const [tab, setTab] = useState(tabsStates[0].id);

  const [outputsToShow, setOutputsToShow] = useState([]);
  const [logsToShow, setLogsToShow] = useState([]);
  const [purchasesStockToShow, setPurchasesStockToShow] = useState([]);

  const [showModalRemovePurchase, setShowModalRemovePurchase] = useState(false);
  const [showModalVisualizePurchase, setShowModalVisualizePurchase] = useState(false);
  const [showModalEditPurchase, setShowModalEditPurchase] = useState(false);
  const [showModalAddPurchase, setShowModalAddPurchase] = useState(false);

  const [refreshDataFromServer, setRefreshDataFromServer] = useState(0);

  let productsOptions = [];
  products &&
    !products.error &&
    products.forEach((element) => {
      if (element.isactive) {
        productsOptions.push({
          id: element?.id_product,
          label: element?.product_name,
        });
      }
    });

  productsOptions.sort((a, b) => {
    const labelA = a.label.trim().toUpperCase();
    const labelB = b.label.trim().toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  let subsidiariesOptions = [];
  subsidiaries &&
    !subsidiaries.error &&
    subsidiaries?.forEach((element) => {
      if (element.isactive) {
        subsidiariesOptions.push({
          id: element?.id_subsidiary,
          label: element?.subsidiary_name,
        });
      }
    });

  subsidiariesOptions.sort((a, b) => {
    const labelA = a.label.trim().toUpperCase();
    const labelB = b.label.trim().toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  let providersOptions = [];
  providers &&
    !providers.error &&
    providers?.forEach((element) => {
      if (element.isactive) {
        providersOptions.push({
          id: element?.id_providers,
          label: element?.providers_name,
        });
      }
    });

  providersOptions.sort((a, b) => {
    const labelA = a.label.trim().toUpperCase();
    const labelB = b.label.trim().toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });

  useEffect(() => {
    async function getPurchasesToStock() {
      setPurchasesStockToShow([]);
      setTotalPagesForPurchasesStock(0);

      try {
        let formattedFilters = {
          startDate: filters.startDate ? filters.startDate : "''",
          endDate: filters.endDate ? filters.endDate : "''",
          provider: filters?.provider?.id
            ? filters?.provider?.label
            : "''",
          subsidiary: filters?.subsidiary?.id
            ? filters?.subsidiary?.label
            : "''",
          page: filters?.page,
          limit: filters?.limit,
          is_apportioned: true,
        };

        let filteredPurchacesToStockResponse = await getFinishedProductOrderPurchases(formattedFilters);

        if (filteredPurchacesToStockResponse.success) {
          const stockPurchases = [];

          filteredPurchacesToStockResponse.data?.data?.map((purchase) => (
            stockPurchases.push({
              id: purchase.id,
              supplier: purchase.supplier,
              supplier_name: providers?.find((provider) => provider.id_providers === purchase.supplier)?.providers_name,
              notes: purchase.notes,
              discount: purchase.discount,
              payment_type: purchase.payment_type,
              payment_type_name: paymentTypes?.find((paymentType) => paymentType.id_paymenttype === purchase.payment_type)?.paymenttype_name,
              subsidiary: purchase.subsidiary,
              subsidiary_name: subsidiaries?.find((subsidiary) => subsidiary.id_subsidiary === purchase.subsidiary)?.subsidiary_name,
              is_apportioned: purchase.is_apportioned,
              purchase_date: purchase.purchase_date,
              parcels: purchase.parcels,
              items: purchase.items.map((item) => ({
                ...item,
                product_name: products?.find((product) => product.id_product === item.product).product_name,
                cost_category_name: costCategories?.find((costCategory) => costCategory.id_category === item.cost_category)?.costs_categories_name,
                total_value: item.unit_price * item.amount,
              })),
              total_value: purchase.items?.reduce((total, item) => total + item.unit_price * item.amount, 0),
            })))

          setPurchasesStockToShow(stockPurchases);
          setTotalPagesForPurchasesStock(filteredPurchacesToStockResponse?.data?.pages);
        } else {
          setTotalPagesForPurchasesStock(1);

          setSnack({
            open: true,
            severity: "error",
            message: filteredPurchacesToStockResponse.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    getPurchasesToStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refreshDataFromServer]);

  useEffect(() => {
    async function getStockItems() {
      setStockItemsToShow([]);
      setTotalPagesForStockItens(0);
      setStockItemsToShowPaginated([]);
      let result = await getFinishedProductOrderStockItems();

      if (result.success) {
        let stockItemsListToShow = result.data?.data?.map((stockItem) => {
          return {
            purchase_id: stockItem.purchase_id,
            item_id: stockItem.id,
            product_name: stockItem.product_name,
            remaining_amount: stockItem.remaining_amount,
            cost_category_name: stockItem.cost_category_name,
            supplier: stockItem.supplier,
            supplier_name: stockItem.supplier_name,
            subsidiary: stockItem.subsidiary,
            subsidiary_name: stockItem.subsidiary_name,
            purchase_date: moment(stockItem.purchase_date).format("DD/MM/YYYY"),
          }
        });

        stockItemsListToShow?.sort((a, b) => {
          const momentA = moment(a.purchase_date, "DD/MM/YYYY");
          const momentB = moment(b.purchase_date, "DD/MM/YYYY");

          if (momentA.isBefore(momentB)) {
            return 1;
          }

          if (momentA.isAfter(momentB)) {
            return -1;
          }

          return 0;
        });

        setStockItemsToShow(stockItemsListToShow);
        setTotalPagesForStockItens(Math.ceil(stockItemsListToShow?.length / filtersCurrentStock.limit));
        setStockItemsToShowPaginated(stockItemsListToShow?.filter((stockItem) =>
        (((filtersCurrentStock.provider.id === 0) || (filtersCurrentStock.provider.id && (filtersCurrentStock.provider.id === stockItem.supplier))) &&
          ((filtersCurrentStock.subsidiary.id === 0) || ((filtersCurrentStock.subsidiary.id && (filtersCurrentStock.subsidiary.id === stockItem.subsidiary))))))?.slice(0, filtersCurrentStock.limit));
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível obter os itens de estoque: ' + result.message,
        });
      }
    }

    getStockItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataFromServer]);

  useEffect(() => {
    async function getOutputsInfo() {
      setOutputsToShow([]);
      setTotalPagesOutputs(0);

      try {
        let formattedFilters = {
          startDate: filtersOutputs.startDate ? filtersOutputs.startDate : "''",
          endDate: filtersOutputs.endDate ? filtersOutputs.endDate : "''",
          product: filtersOutputs?.product?.id
            ? filtersOutputs?.product?.id
            : "''",
          page: filtersOutputs?.pages,
          limit: filtersOutputs?.limit,
        };

        let stockResponse = await getFinishedProductOrderStockOutputs(formattedFilters);

        if (stockResponse.success) {
          let stockOutputsListToShow = stockResponse.data?.data?.map((stockOutput) => {
            return {
              purchase_id: stockOutput.purchase_id,
              allocated_amount: stockOutput.allocated_amount,
              total_withdrawal_value: stockOutput.total_withdrawal_value,
              percent_total_order_value: stockOutput.percent_total_order_value / 100,
              allocated_by_email: stockOutput.allocated_by_email,
              allocated_at: stockOutput.allocated_at,
              recipient_name: stockOutput.recipient_name,
              product_name: stockOutput.product_name,
            }
          });

          stockOutputsListToShow?.sort((a, b) => {
            const momentA = moment(a.allocated_at);
            const momentB = moment(b.allocated_at);

            if (momentA.isBefore(momentB)) {
              return 1;
            }

            if (momentA.isAfter(momentB)) {
              return -1;
            }

            return 0;
          });

          setOutputsToShow(stockOutputsListToShow);
          setTotalPagesOutputs(stockResponse?.data?.pages);
        } else {
          setSnack({
            open: true,
            severity: "error",
            message: stockResponse.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    getOutputsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersOutputs, refreshDataFromServer]);

  useEffect(() => {
    async function getHistoryInfo() {
      setTotalPagesStockHistory(0);
      setCurrentPageForStockHistory(1);
      setLogsToShow([]);

      try {
        let logsResponse = await getFinishedProductOrderStockHistory();

        if (logsResponse.success) {
          setTotalPagesStockHistory(logsResponse.data?.pages);
          setCurrentPageForStockHistory(1);
          setLogsToShow(logsResponse.data?.data);
        } else {
          setSnack({
            open: true,
            severity: "error",
            message: logsResponse.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    getHistoryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageForStockHistory, refreshDataFromServer]);

  useEffect(() => {
    filterPaginatedStockItemsToShow(filtersCurrentStock.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCurrentStock]);

  const handleChangePage = (event, value) => {
    setFilters({
      ...filters,
      page: value,
    });
  };

  function filterPaginatedStockItemsToShow(value) {
    const startIndex = (value - 1) * filtersCurrentStock.limit;
    const endIndex = startIndex + filtersCurrentStock.limit;
    setStockItemsToShowPaginated(stockItemsToShow?.filter((stockItem) =>
    (((filtersCurrentStock.provider.id === 0) || (filtersCurrentStock.provider.id && (filtersCurrentStock.provider.id === stockItem.supplier))) &&
      ((filtersCurrentStock.subsidiary.id === 0) || ((filtersCurrentStock.subsidiary.id && (filtersCurrentStock.subsidiary.id === stockItem.subsidiary))))))?.slice(startIndex, endIndex));
  }

  const handleChangePageStockItems = (event, value) => {
    setFiltersCurrentStock({
      ...filtersCurrentStock,
      page: value,
    });
  };

  const handleChangePageOutputs = (event, value) => {
    setFiltersOutputs({
      ...filtersOutputs,
      page: value,
    });
  };

  const handleChangePageStockHistory = (event, value) => {
    setCurrentPageForStockHistory(value);
  };

  function handleStockItemRowClick(item) {
    const purchaseSelected = purchasesStockToShow.find((purchase) => purchase.id === item.purchase_id);

    setSelectedPurchaseItem({
      item: purchaseSelected
    });

    setShowModalVisualizePurchase(true);
  }

  function handleStockOutputRowClick(item) {
    handleStockItemRowClick(item);
  }

  function verifyFilterProps(obj) {
    let count = 0;
    for (const prop in obj) {
      if (
        obj.hasOwnProperty(prop) &&
        prop !== "limit" &&
        prop !== "page" &&
        prop !== "is_apportioned" &&
        obj[prop].id !== 0 &&
        obj[prop] !== ''
      ) {
        count++;
      }
    }
    return count;
  }

  let activeFilters = 0;

  if (tab === "outputs_stock") {
    activeFilters = verifyFilterProps(filtersOutputs);
  }

  if (tab === "purchases_stock") {
    activeFilters = verifyFilterProps(filters);
  }

  if (tab === "current_stock") {
    activeFilters = verifyFilterProps(filtersCurrentStock);
  }

  let filterButtonLabel = activeFilters
    ? "Filtros(" + activeFilters + ")"
    : "Filtros";

  function cleanFilters() {
    setFilters({ ...defaultFilters });
  }

  function cleanFiltersOutputs() {
    setFiltersOutputs({ ...defaultFiltersOutputs });
  }

  function cleanFiltersCurrentStock() {
    setFiltersCurrentStock({ ...defaultFiltersCurrentStock });
  }

  function Filters() {
    return (
      <>
        <div
          style={{
            marginTop: 16,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            label="Limpar filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => {
              cleanFilters();
            }}
          ></Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Datepicker
            label="Compras entre"
            width={200}
            disabled={false}
            value={filters?.startDate}
            fontSize={'0.7rem'}
            onChange={(element) => {
              let filtersCopy = { ...filters };
              filtersCopy.startDate = element;
              filtersCopy.page = 1;
              setFilters(filtersCopy);
            }} />
          <Datepicker
            label="e"
            width={200}
            disabled={false}
            value={filters?.endDate}
            fontSize={'0.7rem'}
            onChange={(element) => {
              let filtersCopy = { ...filters };
              filtersCopy.endDate = element;
              filtersCopy.page = 1;
              setFilters(filtersCopy);
            }} />
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.provider}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todos" }, ...providersOptions]}
            fontSize={"0.7rem"}
            label="Fornecedor"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.provider = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filters.subsidiary}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todas" }, ...subsidiariesOptions]}
            fontSize={"0.7rem"}
            label="Subsidiária"
            onChange={(value) => {
              const copyFilters = { ...filters };
              copyFilters.subsidiary = value;
              setFilters(copyFilters);
            }}
          ></Autocomplete>
        </div>
      </>
    );
  }

  function FiltersOutputs() {
    return (
      <>
        <div
          style={{
            marginTop: 16,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            label="Limpar filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => {
              cleanFiltersOutputs();
            }}
          ></Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Datepicker
            label="Movimentação entre"
            width={200}
            disabled={false}
            value={filtersOutputs?.startDate}
            fontSize={'0.7rem'}
            onChange={(element) => {
              let filtersCopy = { ...filtersOutputs };
              filtersCopy.startDate = element;
              filtersCopy.page = 1;
              setFiltersOutputs(filtersCopy);
            }} />
          <Datepicker
            label="e"
            width={200}
            disabled={false}
            value={filtersOutputs?.endDate}
            fontSize={'0.7rem'}
            onChange={(element) => {
              let filtersCopy = { ...filtersOutputs };
              filtersCopy.endDate = element;
              filtersCopy.page = 1;
              setFiltersOutputs(filtersCopy);
            }} />
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filtersOutputs.product}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todos" }, ...productsOptions]}
            fontSize={"0.7rem"}
            label="Item"
            onChange={(value) => {
              const copyFilters = { ...filtersOutputs };
              copyFilters.product = value;
              setFiltersOutputs(copyFilters);
            }}
          ></Autocomplete>
        </div>
      </>
    );
  }

  function FiltersCurrentStock() {
    return (
      <>
        <div
          style={{
            marginTop: 16,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            label="Limpar filtros"
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => {
              cleanFiltersCurrentStock();
            }}
          ></Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filtersCurrentStock.provider}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todos" }, ...providersOptions]}
            fontSize={"0.7rem"}
            label="Fornecedor"
            onChange={(value) => {
              const copyFilters = { ...filtersCurrentStock };
              copyFilters.provider = value;
              setFiltersCurrentStock(copyFilters);
            }}
          ></Autocomplete>
        </div>
        <div style={{ marginTop: 16 }}>
          <Autocomplete
            value={filtersCurrentStock.subsidiary}
            width={200}
            disabled={false}
            options={[{ id: 0, label: "Todas" }, ...subsidiariesOptions]}
            fontSize={"0.7rem"}
            label="Subsidiária"
            onChange={(value) => {
              const copyFilters = { ...filtersCurrentStock };
              copyFilters.subsidiary = value;
              setFiltersCurrentStock(copyFilters);
            }}
          ></Autocomplete>
        </div>
      </>
    );
  }

  function renderFilters() {
    if (tab === "outputs_stock") {
      return <FiltersOutputs></FiltersOutputs>;
    } else if (tab === "current_stock") {
      return <FiltersCurrentStock></FiltersCurrentStock>;
    }

    return <Filters></Filters>;
  }

  function handleSelectedPurchaseItem(item) {
    setSelectedPurchaseItem(item);

    if (item.mode === 'edit') {
      setShowModalEditPurchase(true);
    }

    if (item.mode === 'remove') {
      setShowModalRemovePurchase(true);
    }

    if (item.mode === 'visualize') {
      setShowModalVisualizePurchase(true);
    }
  }

  function renderTable() {
    if (tab === "outputs_stock") {
      return (
        <>
          <Table
            columns={columnsOutputs}
            rows={outputsToShow || []}
            onRowClick={handleStockOutputRowClick}
            setSelectedItem={setSelectedItem}
            type="outputs"
            height="600px"
          ></Table>
          <RowPagination>
            <PaginationComponent
              totalPages={totalPagesOutputs}
              page={filtersOutputs?.page}
              handleChangePage={handleChangePageOutputs}
            />
          </RowPagination>
        </>
      );
    }
    if (tab === "purchases_stock") {
      return (
        <>
          <TableNestedItemsAndParcels
            columns={columnsPurchasesStock}
            columnsParcels={columnsParcels}
            columnsItems={columnsItems}
            rows={purchasesStockToShow || []}
            setSelectedItem={handleSelectedPurchaseItem}
            height="600px"
            hasEditing={true}
            hasRemoving={true}
          ></TableNestedItemsAndParcels>
          <RowPagination>
            <PaginationComponent
              totalPages={totalPagesForPurchasesStock}
              page={filters?.page}
              handleChangePage={handleChangePage}
            />
          </RowPagination>
        </>
      );
    }
    if (tab === "logs_stock") {
      return (
        <>
          <Table
            columns={columnsLogs}
            rows={logsToShow || []}
            setSelectedItem={() => { }}
            height="600px"
          ></Table>
          <RowPagination>
            <PaginationComponent
              totalPages={totalPagesStockHistory}
              page={currentPageForStockHistory}
              handleChangePage={handleChangePageStockHistory}
            />
          </RowPagination>
        </>
      );
    }
    return (
      <>
        <Table
          columns={columnsActualStock}
          rows={stockItemsToShowPaginated || []}
          onRowClick={handleStockItemRowClick}
          setSelectedItem={() => { }}
          height="600px"
        ></Table>
        <RowPagination>
          <PaginationComponent
            totalPages={totalPagesForStockItens}
            page={filtersCurrentStock.page}
            handleChangePage={handleChangePageStockItems}
          />
        </RowPagination>
      </>
    );
  }

  return (
    <Container>
      <Header title="Estoque Produtos Acabados" subtitle="Gerencie os itens do estoque das compras para produtos acabados" />
      <Content>
        <Drawer
          open={openDrawer}
          handleOpen={setOpenDrawer}
          Children={renderFilters}
        ></Drawer>
        <Row style={{ marginBottom: 8 }}>
          <Tabs>
            {tabsStates.map((state, index) => {
              if (state.id === tab) {
                return (
                  <h4
                    key={index}
                    style={{
                      color: "#256CE1",
                      borderBottom: "2px solid #256CE1",
                      fontWeight: 600,
                    }}
                    onClick={() => setTab(state.id)}
                  >
                    {state?.label}
                  </h4>
                );
              }
              return (
                <h4 key={index} onClick={() => setTab(state.id)}>
                  {state?.label}
                </h4>
              );
            })}
          </Tabs>
          <Button
            label="Adicionar Compra"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => setShowModalAddPurchase(true)}
          ></Button>
          <Button
            label={filterButtonLabel}
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={(tab === "logs_stock")}
            onClick={() => setOpenDrawer(true)}
          ></Button>
        </Row>
        {renderTable()}
      </Content>
      {showModalAddPurchase && (
        <ModalAddPurchase
          open={showModalAddPurchase}
          setShowModalAddPurchase={setShowModalAddPurchase}
          setSelectedPurchaseItem={setSelectedItem}
          width={1100}
          height={650}
          setRefreshPurchaseData={setRefreshDataFromServer}
        />
      )}
      {showModalRemovePurchase && (
        <ModalRemovePurchase
          open={showModalRemovePurchase}
          width={600}
          height={220}
          selectedPurchaseItem={selectedPurchaseItem}
          setSelectedPurchaseItem={setSelectedPurchaseItem}
          setShowModalRemovePurchase={setShowModalRemovePurchase}
          setRefreshPurchaseData={setRefreshDataFromServer}
        />
      )}
      {showModalVisualizePurchase && (
        <ModalVisualizePurchase
          open={showModalVisualizePurchase}
          width={1000}
          height={450}
          selectedPurchaseItem={selectedPurchaseItem}
          setSelectedPurchaseItem={setSelectedPurchaseItem}
          setShowModalVisualizePurchase={setShowModalVisualizePurchase}
          columnsParcels={columnsParcels}
          columnsItems={columnsItems}
        />
      )}
      {showModalEditPurchase && (
        <ModalEditPurchase
          open={showModalEditPurchase}
          width={1100}
          height={650}
          selectedPurchaseItem={selectedPurchaseItem}
          setSelectedPurchaseItem={setSelectedPurchaseItem}
          setShowModalEditPurchase={setShowModalEditPurchase}
          setRefreshPurchaseData={setRefreshDataFromServer}
        />
      )}
    </Container>
  );
}

export default React.createElement(FinishedProductOrderStock);
