
import { createContext, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface BeneficiationCompany {
    id: string;
    name: string;
    description: string;
}

type BeneficiationCompanyInput = Omit<BeneficiationCompany, 'id'>

interface BeneficiationCompaniesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface BeneficiationCompaniesContextData {
    getBeneficiationCompanies: () => Promise<ResponseProps>;
    createBeneficiationCompany: (beneficiationCompany: BeneficiationCompanyInput) => Promise<ResponseProps>;
    updateBeneficiationCompany: (idBeneficiationCompany: string, beneficiationCompany: BeneficiationCompanyInput) => Promise<ResponseProps>;
    deleteBeneficiationCompany: (idBeneficiationCompany: number) => Promise<ResponseProps>;
}

const BeneficiationCompaniesContext = createContext<BeneficiationCompaniesContextData>(
    {} as BeneficiationCompaniesContextData
);

export function BeneficiationCompaniesProvider({ children }: BeneficiationCompaniesProvidersProps) {
    async function getBeneficiationCompanies() {
        let params = {
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };

        const response = await api.get('/beneficiation-companies', { params: { ...api.defaults.params, ...params } });

        if (response.status === 200) {
            return response.data;
        }
        return {
            success: false,
            message: "Erro ao consultar transações relacionadas ao pedido.",
        }
    }

    async function deleteBeneficiationCompany(idBeneficiationCompany: number) {
        const response = await api.delete(`/beneficiation-companies/${idBeneficiationCompany}`);

        if (response.status === 200) {
            return {
                success: true,
                message: "Removido com sucesso!"
            }
        }

        return {
            success: false,
            message: "Erro ao remover."
        }
    }

    async function createBeneficiationCompany(beneficiationCompanyInput: BeneficiationCompanyInput) {
        const beneficiationCompanyInfo = {
            name: beneficiationCompanyInput.name,
            description: beneficiationCompanyInput.description,
        }

        const response = await api.post('/beneficiation-companies', beneficiationCompanyInfo);

        if (response.status === 201) {
            return {
                success: true,
                message: "Adicionado com sucesso!"
            }
        }

        return {
            success: false,
            message: "Erro ao adicionar."
        }
    }

    async function updateBeneficiationCompany(idBeneficiationCompany: string, beneficiationCompany: BeneficiationCompanyInput) {
        const response = await api.put(`/beneficiation-companies/${idBeneficiationCompany}`, beneficiationCompany);

        if (response.status === 200) {
            return {
                success: true,
                message: "Editado com sucesso!"
            }
        }

        return {
            success: false,
            message: "Erro ao editar."
        }
    }

    return (
        <BeneficiationCompaniesContext.Provider value={{
            getBeneficiationCompanies,
            createBeneficiationCompany,
            updateBeneficiationCompany,
            deleteBeneficiationCompany
        }}>
            {children}
        </BeneficiationCompaniesContext.Provider>
    );
}

export function UseBeneficiationCompanies() {
    const context = useContext(BeneficiationCompaniesContext);
    return context;
}