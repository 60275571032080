import React, { useState, useContext, useEffect } from 'react'
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import {
  Container, Content, Row
} from './styles';
import { UseProducts } from "../../../hooks/useProducts"
import Searchbar from "../../../components/Searchbar";
import Button from "../../../components/Button";
import ModalAddProduct from './ModalAddProduct/index';
import ModalEditProduct from './ModalEditProduct/index';
import ModalRemoveProduct from './ModalRemoveProduct/index';
import AppContext from "../../../state/App.context";

const columns = [
  {
    name: 'Nome',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Descrição',
    key: 'description',
    type: 'string',
    unit: '',
  },
]

function FinishedProducts() {
  const { products } = UseProducts();
  const [search, setSearch] = useState('');
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({ open: false, mode: '', product_name: '', id_product: null });
  const [, setSnack] = useContext(AppContext).snackState;

  useEffect(() => {
    if (products.error) {
      setSnack({
        open: true,
        severity: 'error',
        message: products.error,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  let productsToShow = products && !products.error && products.filter(a => a.type === 'Produto Acabado' && a.isactive);
  
  if (search.length > 0) {
    productsToShow = productsToShow.filter(a => a.product_name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <Container>
      <Header title="Produtos Acabados" subtitle="Gerencie os produtos acabados cadastrados" />
      <Content>
        <Row>
          <Button
            label="Adicionar produto acabado"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => setOpenModalAddProduct(true)}
          ></Button>
          <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar produto acabado..." fontSize={'0.7rem'} ></Searchbar>
        </Row>
        <Table
          columns={columns}
          rows={productsToShow || []}
          hasEditing={true}
          hasRemoving={true}
          setSelectedItem={setSelectedProduct}
        ></Table>
      </Content>
      <ModalAddProduct
        open={openModalAddProduct}
        handleOpen={setOpenModalAddProduct}
        width={700}
        height={430}
      ></ModalAddProduct>
      <ModalEditProduct
        open={selectedProduct.open && selectedProduct.mode === 'edit'}
        setSelectedItem={setSelectedProduct}
        selectedItem={selectedProduct}
        width={700}
        height={330}
      ></ModalEditProduct>
      <ModalRemoveProduct
        open={selectedProduct.open && selectedProduct.mode === 'remove'}
        setSelectedItem={setSelectedProduct}
        productName={selectedProduct?.product_name}
        id_product={selectedProduct?.id_product}
        width={600}
        height={170}
      ></ModalRemoveProduct>
    </Container>
  );
};

export default React.createElement(FinishedProducts);