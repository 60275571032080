import React, { useEffect, useState } from 'react'

import { Container, Content, Row, RowContent, RowFilters, RowPagination, Title } from './styles';
import OrdersFilters from './Filters';

import Header from '../../components/Header';
import Drawer from "../../components/Drawer";
import Button from "../../components/Button";
import Searchbar from '../../components/Searchbar';
import PaginationComponent from '../../components/Pagination';
import Spinner from '../../components/Spinner';

import { columns, defaultFilters } from './options';
import { transformDateFormat } from '../../services/functions';
import ModalAddFinishedProductOrder from './ModalAddFinishedProductOrder';
import TableFinishedProductOrders from '../../components/TableFinishedProductOrders';
import ModalVisualizeFinishedProductOrder from './ModalVisualizeFinishedProductOrder';
import ModalManageCosts from './ModalManageCosts';
import ModalManageBeneficiationItems from './ModalManageBeneficiationItems';
import { UseFinishedProductOrders } from '../../hooks/useFinishedProductOrders';
import ModalRemoveFinishedProductOrder from './ModalRemoveFinishedProductOrder';
import ModalManageItemsSale from './ModalManageItemsSale';
import ModalStockWithdrawal from './ModalStockWithdrawal';

function FinishedProductOrders() {
  const { getFilteredFinishedProductOrders } = UseFinishedProductOrders();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [search, setSearch] = useState('');
  const [totalPages/* , setTotalPages */] = useState(1);
  const [openModalAddFinishedProductOrder, setOpenModalAddFinishedProductOrder] = useState(false);
  const [selectedFinishedProductOrder, setSelectedFinishedProductOrder] = useState('');
  const [finishedProductOrdersToShow, setFinishedProductOrdersToShow] = useState([]);
  const [updateFinishedProductOrdersList, setUpdateFinishedProductOrdersList] = useState(false);

  useEffect(() => {
    async function fetchRegistersFromServer() {
      try {
        setUpdateFinishedProductOrdersList(false);
        setFinishedProductOrdersToShow([]);

        let formattedFilters = {
          costCenter: filters?.costCenter?.id || "''",
          subsidiary: filters?.subsidiary?.id || "''",
          trip: filters?.trip?.id || "''",
          harvest: filters?.harvest?.id || "''",
          boat: filters?.boat?.id || "''",
          startDate:filters.startDate,
          endDate:filters.endDate ,
          pages: filters?.pages,
          limit: filters?.limit,
        }

        let finishedProducts = await getFilteredFinishedProductOrders(formattedFilters);

        if (finishedProducts && finishedProducts.success &&
          finishedProducts.data && finishedProducts.data.length > 0) {
          setFinishedProductOrdersToShow(finishedProducts.data.map((finishedProductOrder) => {
            return {
              id_finished_product_order: finishedProductOrder.id_finished_product_order,
              id_order: finishedProductOrder.id_order,
              trip_name: finishedProductOrder.trip_name,
              harvest: finishedProductOrder.harvest,
              boat_name: finishedProductOrder.boat_name,
              subsidiary_name: finishedProductOrder.subsidiary_name,
              cost_center_name: finishedProductOrder.cost_center_name,
              created_at: finishedProductOrder.created_at,
              created_by: finishedProductOrder.created_by,
            }
          }));
        }
      } finally {
        setLoading(false);
      }
    }

    setLoading(true);
    fetchRegistersFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, updateFinishedProductOrdersList]);

  let titleToShow = filters.startDate !== "''" && filters.endDate !== "''" ? transformDateFormat(filters.startDate) + '-' + transformDateFormat(filters.endDate) : '';
  let activeFilters = verifyFilterProps(filters);
  let filterButtonLabel = activeFilters ? "Filtros (" + activeFilters + ")" : "Filtros";

  function verifyFilterProps(obj) {
    let count = 0;
    for (let prop in obj) {
      if (Array.isArray(obj[prop])) {
        count++;
      }
      if (prop !== 'pages' && prop !== 'limit' && obj[prop] !== "''") {
        count++;
      }
    }
    return count;
  }

  const handleChangePage = (event, value) => {
    setFilters(
      {
        ...filters,
        pages: value,
      }
    )
  };

  function renderTable() {
    if (loading) {
      return (
        <div style={{ width: '100%', height: 200 }}>
          <Spinner width={40} fontSize={14}></Spinner>
        </div>
      )
    } else {
      return (
        <TableFinishedProductOrders
          columns={columns}
          rows={finishedProductOrdersToShow || []}
          hasEditing={false}
          hasRemoving={true}
          hasVisualizing={true}
          hasCosting={true}
          hasStockWithdrawal={true}
          hasBeneficiationItems={true}
          hasSales={true}
          setSelectedItem={setSelectedFinishedProductOrder}
          actionsInFirstLine={true}
          colorfulRows={true}
          fitWidth={true}
          height="700px"
        ></TableFinishedProductOrders>
      )
    }
  }

  function Filters() {
    return (
      <OrdersFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}></OrdersFilters>
    )
  }

  return (
    <Container>
      <Header title="Pedidos de produto acabado" subtitle="Visualize e gerencie os pedidos de produto acabado" />
      <Content>
        <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
        <RowFilters>
          <Title>{titleToShow}</Title>
          <Searchbar
            value={search}
            onChange={setSearch}
            disabled={false}
            width={300}
            placeholder="Buscar pedido de produto acabado por id..."
            fontSize={'0.7rem'}
          ></Searchbar>
          <Button
            label="Adicionar pedido de produto acabado"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setOpenModalAddFinishedProductOrder(true) }}
          ></Button>
          <Button
            label={filterButtonLabel}
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => setOpenDrawer(true)}
          ></Button>
        </RowFilters>
        <Row>
          <div style={{ height: 16 }}></div>
        </Row>
        <RowContent>
          {renderTable()}
        </RowContent>
        <RowPagination>
          <PaginationComponent totalPages={totalPages} page={filters?.pages} handleChangePage={handleChangePage} />
        </RowPagination>
      </Content>
      {openModalAddFinishedProductOrder && (
        <ModalAddFinishedProductOrder
          open={openModalAddFinishedProductOrder}
          handleOpen={setOpenModalAddFinishedProductOrder}
          width={1000}
          height={900}
          setUpdateFinishedProductOrdersList={setUpdateFinishedProductOrdersList}
        />
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'visualize' && (
        <ModalVisualizeFinishedProductOrder
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'visualize'}
          setSelectedItem={setSelectedFinishedProductOrder}
          selectedItem={selectedFinishedProductOrder}
          width={1100}
          height={900}
        ></ModalVisualizeFinishedProductOrder>
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'remove' && (
        <ModalRemoveFinishedProductOrder
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'remove'}          
          selectedItem={selectedFinishedProductOrder}
          setSelectedItem={setSelectedFinishedProductOrder}
          setUpdateFinishedProductOrdersList={setUpdateFinishedProductOrdersList}
          width={1000}
          height={450}
        />
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'costs' && (
        <ModalManageCosts
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'costs'}          
          selectedItem={selectedFinishedProductOrder}
          setSelectedItem={setSelectedFinishedProductOrder}
          width={1100}
          height={650}
        />
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'stock-withdrawal' && (
        <ModalStockWithdrawal
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'stock-withdrawal'}          
          selectedItem={selectedFinishedProductOrder}
          setSelectedItem={setSelectedFinishedProductOrder}
          width={1100}
          height={500}
        />
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'beneficiation-items' && (
        <ModalManageBeneficiationItems
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'beneficiation-items'}          
          selectedItem={selectedFinishedProductOrder}
          setSelectedItem={setSelectedFinishedProductOrder}
          width={1100}
          height={650}
        />
      )}
      {selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'items-sale' && (
        <ModalManageItemsSale
          open={selectedFinishedProductOrder !== '' && selectedFinishedProductOrder.open && selectedFinishedProductOrder.mode === 'items-sale'}          
          selectedItem={selectedFinishedProductOrder}
          setSelectedItem={setSelectedFinishedProductOrder}
          width={1100}
          height={650}
        />
      )}
    </Container>
  );
};

export default React.createElement(FinishedProductOrders);