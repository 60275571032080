export const columnsParcels = [
  {
    name: 'Nº',
    key: 'name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Data de Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },
]

export const columnsTransactions = [
  {
    name: 'Produto',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Preço unitário',
    key: 'unity_price',
    type: 'money',
    unit: '',
  },

  {
    name: 'Quantidade',
    key: 'product_amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Valor total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de custo',
    key: 'costs_categories_name',
    type: 'string',
    unit: '',
  },

]

export const columnsOutputs = [
  {
    name: 'Produto',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'output_amount',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'output_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Percentual',
    key: 'percent_total_order_value',
    type: 'percent',
    unit: '%',
  },
  {
    name: 'Centro de custo',
    key: 'costs_center_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Barco',
    key: 'boats_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Viagem',
    key: 'trips_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Data/Hora',
    key: 'created_at',
    type: 'datetime',
    unit: '',
  },
  {
    name: 'Responsável',
    key: 'created_by',
    type: 'string',
    unit: '',
  },
]

export const tabsStates = [
  {
      id: 'input-order',
      label: 'Pedido de Entrada',
  },
  {
    id: 'beneficiation',
    label: 'Beneficiamento',
  },
  {
    id: 'sales',
    label: 'Vendas',
  },
  {
    id: 'stock',
    label: 'Estoque',
  },
]

export const columnsFiles = [
  {
    name: 'Nome do arquivo',
    key: 'name',
    type: 'string',
    unit: '',
  },
]

export const columnsCostsAndExpenses = [
  {
    name: 'Produto',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Custo/kg',
    key: 'cost_per_kg',
    type: 'float',
    unit: '',
  },
]

export const columnsBeneficiationItems = [
  {
    name: 'Produto Acabado',
    key: 'finished_product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade Produzida',
    key: 'produced_amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Rendimento do Beneficiamento',
    key: 'beneficiation_yield',
    type: 'string',
    unit: '',
  },
]

export const columnsRawMaterials = [
  {
    name: 'Matéria-prima',
    key: 'raw_material_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Saldo a Processar',
    key: 'balance_to_be_processed',
    type: 'float',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
]

export const columnsSales = [
  {
    name: 'Data da Venda',
    key: 'dt_sale',
    type: 'date',
    unit: '',
  },
  {
    name: 'Comprador',
    key: 'provider_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Vendedor',
    key: 'seller_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Produto Beneficiado',
    key: 'finished_product_order_item_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
]

export const columnsStock = [
  {
    name: 'Produto Acabado',
    key: 'finished_product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade em Estoque',
    key: 'stock_amount',
    type: 'float',
    unit: '',
  },
  {
    name: 'Quantidade Retirada',
    key: 'withdrawn_amount',
    type: 'float',
    unit: '',
  },
]