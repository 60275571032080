import { Container, Header, Row, Content, Divider } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../../assets/icons/index';
import { formatMoney } from "../../../../services/functions";
import Table from "../../../../components/Table";
import moment from 'moment';

export default function ModalVisualizePurchase({ open, width, height, selectedPurchaseItem, setSelectedPurchaseItem, setShowModalVisualizePurchase, columnsParcels, columnsItems }) {
  const purchase = selectedPurchaseItem.item;
  
  function handleClose() {
    open = false;
    setSelectedPurchaseItem(null);
    setShowModalVisualizePurchase(false);
  }

  function renderContent() {
    return (
      <Content>
        <Row>
          <h1 style={{ width: '100%' }}><b>Id da compra:</b> {purchase?.id}</h1>
        </Row>
        <Divider />
        <Row>
          <h4><b>Data de Compra:</b> {moment(purchase?.purchase_date).format("DD/MM/YYYY")}</h4>
          <h4><b>Subsidiária:</b> {purchase?.subsidiary_name}</h4>
          <h4><b>Fornecedor:</b> {purchase?.supplier_name}</h4>
        </Row>
        <Row>
          <h4><b>Valor total:</b> {formatMoney(purchase?.total_value)}</h4>
          <h4><b>Tipo de pagamento:</b> {purchase?.payment_type_name}</h4>
          <h4><b>Desconto:</b> {formatMoney(purchase?.discount)}</h4>
        </Row>
        <Row><h4><b>Observações:</b> {purchase?.notes}</h4></Row>
        <Row></Row>
        <Row><h4><b>Lista de Itens:</b></h4></Row>
        <Table
          columns={columnsItems}
          rows={purchase?.items || []}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
        <Row><h4><b>Pagamento:</b></h4></Row>
        <Table
          columns={columnsParcels}
          rows={purchase?.parcels || []}
          hasEditing={false}
          hasRemoving={false}
          height="auto"
        ></Table>
      </Content>
    )
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Detalhes da Compra para Pedido de Produto Acabado</h1>
          <img src={Close} onClick={handleClose} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}