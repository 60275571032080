import {useState, useEffect, useContext} from 'react';
import {Container, Header, Row, Content, ListTitle, Divider} from './styles';
import Modal from '@mui/material/Modal';
import {Close} from '../../../../assets/icons/index';
import Table from '../../../../components/Table';
import moment from 'moment';
import { formatMoney } from '../../../../services/functions';
import { UseOrders } from '../../../../hooks/useOrders';
import AppContext from "../../../../state/App.context";
import { columnsParcels, columnsTransactions, columnsOutputs } from './options';
import Spinner from "../../../../components/Spinner";

export default function ModalVisualizeOrder({open, setSelectedItem, width, height, selectedItem, setOpenModalVisualizeOrder}){
    const { getOrderTransactions, getOrderDetails} = UseOrders();
    const [order, setOrder] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [, setSnack] = useContext(AppContext).snackState;
    const [loading, setLoading] = useState(true);

    let parcels = [];
    if(order?.amount_parcels){
      for (let i = 0; i < order.amount_parcels; i++){
          parcels.push(
            {
              name: i+1,
              expiration_date: order?.expiration_date[i],
              payment_date: order?.status[i] === 'Executado' ?  order?.payment_date[i] : '',
              status: order?.status[i],
              price: order?.values_parcels[i],
            }
          )
        } 
    } 

    let totalOutputs = 0;
    if(order?.saidas_estoque && order?.saidas_estoque.length > 0){
      order?.saidas_estoque.map((output)=>{
        totalOutputs = totalOutputs + output?.output_value;
      })
    }


    const title = 'Detalhes do pedido';

    async function getOrderInfo(){
      try{
        if(!loading) setLoading(true);
        let responseOriginalOrder = await getOrderDetails(selectedItem?.id_order);
        if(responseOriginalOrder.success){
          setOrder(responseOriginalOrder?.data[0]);
        }
      } catch(err){
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    useEffect(()=>{
      if(open) getOrderInfo()
   },[open, selectedItem])

    async function getTransactionsInfo(){
      try{
        let responseTransactions = await getOrderTransactions(selectedItem.id_order);
        if(responseTransactions.success){
          setTransactions(responseTransactions?.data.filter(element => element.isactive));
        } else {
          setSnack({
            open: true,
            severity: 'error', 
            message: responseTransactions?.message,
          })
        }
      } catch(err){
        console.log(err);
      }
    }

    useEffect(()=>{
       if(order && order?.id_order) getTransactionsInfo();
    },[order])

    function renderParcels(){
      if(order && order.amount_parcels){
        return (
                <>
                <Row style={{marginBottom:8}}>
                <h4><b>Parcelas:</b></h4>
                </Row>
                <Table 
                  columns={columnsParcels} 
                  rows={parcels || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  height="160px"
                  actionsInFirstLine={false}
                  fitWidth={false}
                ></Table>
                </>
        )
      } else {
        return (
          <Row>
          <h4><b>Data de vencimento:</b> {order.expiration_date ? moment(order?.expiration_date[0]).add(3, 'hours').format('DD/MM/YYYY') : ''}</h4>
          <h4><b>Data de pagamento:</b> {order.status ? order?.status[0] === 'Executado' ? moment(order?.payment_date[0]).add(3, 'hours').format('DD/MM/YYYY') : '-' : ''}</h4>
          <h4><b>Status:</b> {order.status ? order?.status[0] : ''}</h4> 
          </Row>
        )
      }
    }


    function renderOutputs(){
      if(order && order.saidas_estoque){
        return (
                <>
                 <Row>
                  <ListTitle>Movimentações do estoque:</ListTitle>
                  </Row>
                 <Divider></Divider>
                <Table 
                  columns={columnsOutputs} 
                  rows={order.saidas_estoque || []} 
                  hasEditing={false} 
                  hasRemoving={false}
                  setSelectedItem={()=>{}} 
                  height="100%"
                  actionsInFirstLine={false}
                  fitWidth={false}
                ></Table>
                </>
        )
      } else {
        return (
          <></>
        )
      }
    }

    function renderUpdatedValue(){
      if(order && order.total_order_value_updated){
        return (
                <>
                  <Row>
                  <h4 style={{width:'100%'}}><b>Valor total de retiradas:</b> {totalOutputs ? formatMoney(totalOutputs) : ''} </h4>
                </Row>
                  <Row>
                  <h4 style={{width:'100%'}}><b>Valor atualizado do pedido:</b> {order?.total_order_value_updated ? formatMoney(order?.total_order_value_updated) + ' (valor utilizado nos cálculos)' : ''} </h4>
                </Row>
                </>
           )
      } else {
        return (
          <></>
        )
      }
    }


    function renderPaymentInfo(){
      if(order?.original_order){
        return (
          <>
          <Row>
            <h4><b>Valor total do pedido:</b> {order.total_order_value ? formatMoney(order?.total_order_value) : ''}</h4>
            <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name  || ''}</h4>
          </Row>
          </>
        )
      } else {
        return (
          <>
              <Row>
                  <h4><b>Valor total original:</b> {order.total_order_value? formatMoney(order?.total_order_value) : '-' }</h4>
                  <h4><b>Desconto:</b> {order.discount ? formatMoney(order?.discount) : ''}</h4> 
                  <h4><b>Valor total original com desconto:</b> {formatMoney(order?.total_order_value - order?.discount) }</h4>
                </Row>
                <Row>
                  <h4><b>Tipo de pagamento:</b> {order?.paymenttype_name  || ''}</h4>
                  <h4><b>Qtd. de parcelas:</b> {order.amount_parcels ? parseInt(order?.amount_parcels) : '0'}</h4>
                  <h4><b>Recibo:</b> {order?.receipt || 'Sem recibo'}</h4>
                </Row>
              {renderParcels()}
          </>
        )
      }
    }

     function renderContent(){
      if(loading){
        return (
          <Spinner width={40} fontSize={14}></Spinner>
        )}
            return(
            <Content>
              <Row><ListTitle>Informações do pedido:</ListTitle></Row>
              <Divider></Divider>
                <Row>
                  <h4 style={{width:'100%'}}><b>Id:</b> {order?.id_order}</h4>
                </Row> 
                <Row>
                  <h4><b>Tipo:</b> {order?.type}</h4>
                  <h4><b>Subsidiária:</b> {order?.subsidiary_name}</h4>
                  <h4><b>{order?.type === 'Entrada' ? 'Comprador' : 'Fornecedor'}</b>: {order?.providers_name}</h4>
                </Row> 
                 <Row style={{marginBottom: 8}}>
                  <h4><b>Criado em:</b> {moment(order?.created_at).format('DD/MM/YYYY [às] HH:mm')}</h4>
                  <h4><b>Criado por:</b> {order?.created_by}</h4>
                  <h4><b>Aprovado por:</b> {order?.approved === true ? order?.approved_by : 'Não aprovado'}</h4>
                 </Row>
                 <Row>
                  <ListTitle>Produtos:</ListTitle>
                  </Row>
                 <Divider></Divider>
                <Table 
                    columns={columnsTransactions} 
                    rows={transactions || []} 
                    hasEditing={false} 
                    hasRemoving={false}
                    setSelectedItem={()=>{}} 
                    actionsInFirstLine={false}
                    height="100%"
                    fitWidth={false}
                  ></Table>  
                 <Row>
                  <ListTitle>Pagamento:</ListTitle>
                  {/* <Button
                  label="Atualizar pagamento"
                  background="white" 
                  color="#256CE1" 
                  borderColor="#256CE1" 
                  disabled={false}
                  onClick={() => {}}
                ></Button> */}
                  </Row>
              <Divider></Divider>
               {order && renderPaymentInfo()}
              <br></br>
               {renderOutputs()}
               {renderUpdatedValue()}
              <Row></Row>
        </Content>
            )
            
        }


    return (
        <Modal
        open={open}
        onClose={() =>  {setSelectedItem({open: false, mode: '', name: '', id_order: null});setOpenModalVisualizeOrder(false); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Container style={{width, height}}>
          <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() =>  {setSelectedItem({open: false, mode: '', name: '', id_order: null});setOpenModalVisualizeOrder(false); }} alt="Fechar"></img>
          </Header>
          {renderContent()}
        </Container>
      </Modal>
       )
  }