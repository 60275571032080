import styled from 'styled-components';

export const Container = styled.div`
  background: var(--background);
  height: 100vh;
  width: 100%;
  padding: 32px;
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color: #989898 #FFFFFF; 
  
  &::-webkit-scrollbar {
    width: 6px; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: #989898; 
    border-radius: 4px; 
  }

  &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  }
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;

export const Row = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;