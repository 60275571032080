import { useState, useContext } from "react";
import Button from "../../../components/Button";
import Datepicker from "../../../components/Datepicker";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import Spinner from "../../../components/Spinner";
import { UseOrders } from "../../../hooks/useOrders";
import moment from "moment";
import AppContext from "../../../state/App.context";
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";

export default function ModalChangeMultiplesExpirationDates({ open, selectedItems, setSelectedItems, width, height, setRefreshData }) {
  const { shortcutEditOrder } = UseOrders();
  const { updateCostParcelsPaymentData, updateSaleParcelsPaymentData } = UseFinishedProductOrders();
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [newExpirationDate, setNewExpirationDate] = useState(new Date());
  const title = 'Mudar data de vencimento';
  const fontSize = 14;

  function handleClose() {
    setSelectedItems([]);
    setNewExpirationDate(new Date());
    setRefreshData(prevIsRefreshData => !prevIsRefreshData);
  }

  function addTimestampToArray(newElement, index, array, amount_parcels) {
    newElement = newElement || new Date();

    if (isNaN(newElement)) {
      newElement = new Date();
    }

    if (index === 0 && amount_parcels !== 0) {
      return "timestamp '" + moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
    }
    if (index === 0 && amount_parcels === 0) {
      return "timestamp '" + moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
    }
    if (index === (amount_parcels - 1)) {
      return array + "timestamp '" + moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "'";
    }
    return array + "timestamp '" + moment(newElement).format('YYYY-MM-DD HH:mm:ss') + "',";
  }

  function addStringToArray(newElement, index, array, amount_parcels) {
    if (index === 0 && amount_parcels !== 0) {
      return "'" + newElement + "',";
    }
    if (index === 0 && amount_parcels === 0) {
      return "'" + newElement + "'";
    }
    if (index === (amount_parcels - 1)) {
      return array + "'" + newElement + "'";
    }
    return array + "'" + newElement + "',";
  }

  async function handleOrder() {
    let ids_array = [];
    let status_arrays = [];
    let payment_date_arrays = [];
    let expiration_date_arrays = [];

    selectedItems.filter((order_item) => order_item.isFinishedProductOrder === false).forEach((row) => {
      let amount_parcels = row?.amount_parcels;
      let status_array = '';
      let expiration_date_array = '';
      let payment_date_array = '';
      let new_expiration_date = moment(newExpirationDate).add(3, 'hours');
      let current_parcel_index = row?.current_parcel_name.split('/')[0] - 1;
      const status = row?.status[current_parcel_index];
      const payment_date = moment(row?.payment_date[current_parcel_index]).add(3, 'hours');

      if (amount_parcels === 0) {
        status_array = addStringToArray(status, 0, status_array, amount_parcels);
        expiration_date_array = addTimestampToArray(new_expiration_date, 0, expiration_date_array, amount_parcels);
        payment_date_array = addTimestampToArray(payment_date, 0, payment_date_array, amount_parcels);
      } else {
        row?.status.forEach((status_item, index) => {
          let parcel_new_expiration_date = index === current_parcel_index ? new_expiration_date : moment(row?.expiration_date[index]).add(3, 'hours');
          status_array = addStringToArray(status_item, index, status_array, amount_parcels);
          expiration_date_array = addTimestampToArray(parcel_new_expiration_date, index, expiration_date_array, amount_parcels);
          payment_date_array = addTimestampToArray(moment(row?.payment_date[index]).add(3, 'hours'), index, payment_date_array, amount_parcels);
        });
      };

      ids_array.push(row?.id_order);
      status_arrays.push(status_array);
      payment_date_arrays.push(payment_date_array);
      expiration_date_arrays.push(expiration_date_array);
    })

    if (ids_array.length > 0 || status_arrays.length > 0 || expiration_date_arrays.length > 0 || payment_date_arrays.length > 0) {
      const updatedOrders = {
        id_array: ids_array,
        status_array: status_arrays,
        expiration_date_array: expiration_date_arrays,
        payment_date_array: payment_date_arrays,
      }

      return await shortcutEditOrder(updatedOrders);
    } else {
      return {
        success: true,
      };
    }
  }

  async function handleFinishedProductOrderCost() {
    let ids_array = [];
    let status_arrays = [];
    let payment_date_arrays = [];
    let expiration_date_arrays = [];

    selectedItems.filter((order_item) => (order_item.isFinishedProductOrder === true) && (order_item.type === 'Saída')).forEach((row) => {
      let amount_parcels = row?.amount_parcels;
      let status_array = '';
      let expiration_date_array = '';
      let payment_date_array = '';
      let new_expiration_date = moment(newExpirationDate).add(3, 'hours');
      let current_parcel_index = row?.current_parcel_name.split('/')[0] - 1;
      const status = row?.status[current_parcel_index];
      const payment_date = moment(row?.payment_date[current_parcel_index]).add(3, 'hours');

      if (amount_parcels === 0) {
        status_array = addStringToArray(status, 0, status_array, amount_parcels);
        expiration_date_array = addTimestampToArray(new_expiration_date, 0, expiration_date_array, amount_parcels);
        payment_date_array = addTimestampToArray(payment_date, 0, payment_date_array, amount_parcels);
      } else {
        row?.status.forEach((status_item, index) => {
          let parcel_new_expiration_date = index === current_parcel_index ? new_expiration_date : moment(row?.expiration_date[index]).add(3, 'hours');
          status_array = addStringToArray(status_item, index, status_array, amount_parcels);
          expiration_date_array = addTimestampToArray(parcel_new_expiration_date, index, expiration_date_array, amount_parcels);
          payment_date_array = addTimestampToArray(moment(row?.payment_date[index]).add(3, 'hours'), index, payment_date_array, amount_parcels);
        });
      };

      ids_array.push(row?.id_order);
      status_arrays.push(status_array);
      payment_date_arrays.push(payment_date_array);
      expiration_date_arrays.push(expiration_date_array);
    })

    if (ids_array.length > 0 || status_arrays.length > 0 || expiration_date_arrays.length > 0 || payment_date_arrays.length > 0) {
      const updatedCostOrders = {
        id_array: ids_array,
        status_array: status_arrays,
        expiration_date_array: expiration_date_arrays,
        payment_date_array: payment_date_arrays,
      }

      return await updateCostParcelsPaymentData(updatedCostOrders);
    } else {
      return {
        success: true,
      };
    }
  }

  async function handleFinishedProductOrderSale() {
    let ids_array = [];
    let status_arrays = [];
    let payment_date_arrays = [];
    let expiration_date_arrays = [];

    selectedItems.filter((order_item) => (order_item.isFinishedProductOrder === true) && (order_item.type === 'Entrada')).forEach((row) => {
      let amount_parcels = row?.amount_parcels;
      let status_array = '';
      let expiration_date_array = '';
      let payment_date_array = '';
      let new_expiration_date = moment(newExpirationDate).add(3, 'hours');
      let current_parcel_index = row?.current_parcel_name.split('/')[0] - 1;
      const status = row?.status[current_parcel_index];
      const payment_date = moment(row?.payment_date[current_parcel_index]).add(3, 'hours');

      if (amount_parcels === 0) {
        status_array = addStringToArray(status, 0, status_array, amount_parcels);
        expiration_date_array = addTimestampToArray(new_expiration_date, 0, expiration_date_array, amount_parcels);
        payment_date_array = addTimestampToArray(payment_date, 0, payment_date_array, amount_parcels);
      } else {
        row?.status.forEach((status_item, index) => {
          let parcel_new_expiration_date = index === current_parcel_index ? new_expiration_date : moment(row?.expiration_date[index]).add(3, 'hours');
          status_array = addStringToArray(status_item, index, status_array, amount_parcels);
          expiration_date_array = addTimestampToArray(parcel_new_expiration_date, index, expiration_date_array, amount_parcels);
          payment_date_array = addTimestampToArray(moment(row?.payment_date[index]).add(3, 'hours'), index, payment_date_array, amount_parcels);
        });
      };

      ids_array.push(row?.id_order);
      status_arrays.push(status_array);
      payment_date_arrays.push(payment_date_array);
      expiration_date_arrays.push(expiration_date_array);
    })

    if (ids_array.length > 0 || status_arrays.length > 0 || expiration_date_arrays.length > 0 || payment_date_arrays.length > 0) {
      const updatedSaleOrders = {
        id_array: ids_array,
        status_array: status_arrays,
        expiration_date_array: expiration_date_arrays,
        payment_date_array: payment_date_arrays,
      }

      return await updateSaleParcelsPaymentData(updatedSaleOrders);
    } else {
      return {
        success: true,
      };
    }
  }

  async function handleChangeExpirationDate(event) {
    try {
      setLoading(true);
      event.preventDefault();
      const responseOrder = await handleOrder();
      const responseFinishedProductOrderCost = await handleFinishedProductOrderCost();
      const responseFinishedProductOrderSale = await handleFinishedProductOrderSale();

      if (responseOrder.success && responseFinishedProductOrderCost.success && responseFinishedProductOrderSale.success) {
        handleClose();
        setSnack({
          open: true,
          severity: 'success',
          message: "Operação realizada com sucesso!",
        });
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Ocorreu um erro no cadastro. Tente novamente ou entre em contato com a equipe técnica.',
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function createIdentifyString() {
    let text = '';
    selectedItems.forEach((item, index) => {
      text = ' ' + text + item?.providers_name + ' [R$' + item?.current_parcel_value + '],';
    });
    return text;
  }


  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <h1>Deseja adiar a data de vencimento de {selectedItems.length} itens ({createIdentifyString()}) para <b>{moment(newExpirationDate).add(3, 'hours').format('DD/MM/YYYY')}</b>?</h1>
          </Row>
          <Datepicker
            label={"Data de vencimento"}
            width="100%"
            disabled={false}
            value={newExpirationDate}
            fontSize={fontSize - 2}
            onChange={(item) => setNewExpirationDate(item)} />
          <Row>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={() => handleClose()}
            ></Button>
            <Button
              label="Salvar alterações"
              background="#256CE1"
              color="white"
              borderColor="#256CE1"
              disabled={false}
              onClick={handleChangeExpirationDate}
            ></Button>
          </Row>
        </Content>
      )

    }
  }


  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>
            {title}
          </h1>
          <img src={Close} onClick={() => handleClose()} alt="Fechar"></img>
        </Header>
        {renderContent()}

      </Container>
    </Modal>
  )

}
