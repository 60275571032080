import styled from 'styled-components';

export const TableContainer = styled.div`
    overflow-x: auto;
    overflow-y: auto;
    scroll-behavior: smooth; /* animação suave do scroll */
    display: flex;
    flex-direction: column;
    background: var(--white);
    padding: 0px 20px;
    position: relative;
    height: 500px;
    scrollbar-width: thin; 
    scrollbar-color: #989898 #FFFFFF; 

    &::-webkit-scrollbar {
        height: 6px; 
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #989898; 
        border-radius: 4px; 
    }

    &::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }
`;

export const TableHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    min-width:100%;
    width: fit-content;
    display: grid;
    grid-template-rows: 1fr;
    border-bottom: 1px solid var(--blue);
    padding-bottom:8px;
    padding-top: 10px;
    background-color: var(--white);

    h3 {
        color: var(--txt-title);
        font-weight: 400;
        font-size: 0.7rem;
        text-align: center;
        letter-spacing: -0.01em;
    }
`;

export const TableRow = styled.div`
    width:100%;
    display: grid;
    grid-template-rows: 1fr;
    padding-bottom:8px;
    cursor: pointer;
    transition: transform .2s;
    
    &:hover {
        background-color:var(--light-grey);
    }

    h3 {
        color: var(--txt-subtitle);
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.01em;
        padding: 0.8rem 0rem;
    }
`;

export const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index:10;
    
    img {
        width: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
        transition: transform .2s;
        
        &:hover {
            transform: scale(1.05);
        }
    }
`;

export const NoData = styled.h1`
    width: 100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        color: var(--blue);
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        padding: 1rem 0rem;
    }

    img {
        width: 120px;
    }
`;