export const columnsParcels = [
  {
    name: 'Nº',
    key: 'name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Vencimento',
    key: 'expiration_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Data de Pagamento',
    key: 'payment_date',
    type: 'date',
    unit: '',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'string',
    unit: '',
  },

]

export const columnsTransactions = [
  {
    name: 'Produto',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Preço unitário',
    key: 'unity_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'product_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de custo',
    key: 'costs_categories_name',
    type: 'string',
    unit: '',
  },

]

export const columnsEmployees = [
  {
    name: 'Funcionário',
    key: 'employee_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Salário',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de custo',
    key: 'costs_categories_name',
    type: 'string',
    unit: '',
  },
]

export const columnsApportionment = [
  {
    name: 'Viagem',
    key: 'trip_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Safra',
    key: 'harvest',
    type: 'string',
    unit: '',
  },
  {
    name: 'Centro de custo',
    key: 'cost_center_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]



export const columnsOutputs = [
  {
    name: 'Produto',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'output_amount',
    type: 'string',
    unit: '',
  },
  {
    name: 'Valor',
    key: 'output_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Percentual',
    key: 'percent_total_order_value',
    type: 'percent',
    unit: '%',
  },
  {
    name: 'Centro de custo',
    key: 'costs_center_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Barco',
    key: 'boats_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Viagem',
    key: 'trips_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Data/Hora',
    key: 'created_at',
    type: 'datetime',
    unit: '',
  },
  {
    name: 'Responsável',
    key: 'created_by',
    type: 'string',
    unit: '',
  },
]

export const columnsFinishedProductOrderCosts = [
  {
    name: 'Insumo',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Preço unitário',
    key: 'unity_price',
    type: 'money',
    unit: '',
  },

  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de custo',
    key: 'costs_categories_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Fornecedor',
    key: 'supplier_name',
    type: 'string',
    unit: '',
  },
]

export const columnsFinishedProductOrderSales = [
  {
    name: 'Produto Beneficiado',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Preço unitário',
    key: 'unity_price',
    type: 'money',
    unit: '',
  },

  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Valor total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
  {
    name: 'Categoria de custo',
    key: 'costs_categories_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Comprador',
    key: 'provider_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Vendedor',
    key: 'seller_name',
    type: 'string',
    unit: '',
  },
]

export const columnsFinishedProductOrderBeneficiationItems = [
  {
    name: 'Matéria-prima',
    key: 'raw_material_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade de Matéria-prima',
    key: 'raw_material_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Peixe Fraco',
    key: 'weakfish',
    type: 'number',
    unit: '',
  },
  {
    name: 'Produto Acabado',
    key: 'finished_product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade Produzida',
    key: 'produced_amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço do Beneficiamento',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]

export const columnsPurchasesItems = [
  {
    name: 'Item',
    key: 'product_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Categoria de Custo',
    key: 'cost_category_name',
    type: 'string',
    unit: '',
  },
  {
    name: 'Quantidade',
    key: 'amount',
    type: 'number',
    unit: '',
  },
  {
    name: 'Preço Unitário',
    key: 'unit_price',
    type: 'money',
    unit: '',
  },
  {
    name: 'Valor Total',
    key: 'total_value',
    type: 'money',
    unit: '',
  },
]