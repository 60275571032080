import { useState, useContext } from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { Container, Header, Row, Content } from './styles';
import Modal from '@mui/material/Modal';
import { Close } from '../../../assets/icons/index';
import AppContext from "../../../state/App.context";
import { UseFinishedProductOrders } from "../../../hooks/useFinishedProductOrders";

export default function ModalRemoveItemSale({ open, width, height, selectedItem, setSelectedItem, setShowModalRemoveItemSale, setRefreshDataFromServer}) {
  const { deleteFinishedProductSale } = UseFinishedProductOrders();
  const [loading, setLoading] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;

  function handleClose() {
    open = false;
    setSelectedItem(null);
    setShowModalRemoveItemSale(false);
  }

  async function handleRemoveItemSale(event) {
    try {
      setLoading(true);
      event.preventDefault();
      let response = await deleteFinishedProductSale(selectedItem.id);
      
      if (response.success) {
        setRefreshDataFromServer((prevValue) => prevValue + 1);
        handleClose();
        
        setSnack({
          open: true,
          severity: 'success',
          message: response?.message,
        })
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não foi possível remover a venda de produto acabado selecionada. Tente novamente ou entre em contato com a equipe técnica.',
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Spinner width={40} fontSize={14}></Spinner>
      )
    } else {
      return (
        <Content>
          <Row>
            <h1>Deseja remover a venda de produto acabado selecionada <b>{selectedItem.finished_product_order_item_name}</b>? <b>Essa ação não poderá ser desfeita.</b></h1>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <Button
              label="Cancelar"
              background="#8E92BC"
              color="white"
              borderColor="#8E92BC"
              disabled={false}
              onClick={handleClose}
            ></Button>
            <Button
              label="Remover item"
              background="#FF4D5E"
              color="white"
              borderColor="#FF4D5E"
              disabled={false}
              onClick={handleRemoveItemSale}
            ></Button>
          </Row>
        </Content>
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container style={{ width, height }}>
        <Header>
          <h1>Remover Venda de Produto Acabado</h1>
          <img src={Close} onClick={handleClose} alt="Fechar"></img>
        </Header>
        {renderContent()}
      </Container>
    </Modal>
  )
}